import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router';

import { objectMerge, resolveParams, resolveState } from '@labradorsports/utils';

import { RootState } from '../../../store/index.js';
import { useDispatcher } from '../../hooks/index.js';

import { baseParamState, paramSetup } from './url-state-config.js';

const RouterStateSync: FC = () => {
    const updateState = useDispatcher((payload) => {
        return { type: 'UPDATE_STATE', payload };
    });
    const state = useSelector((state: RootState) => state);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const prevResolvedParams = useRef(null);
    const skipLocationUpdate = useRef(true);

    useEffect(() => {
        // Skip location update when state update was caused by previous location update
        if (skipLocationUpdate.current) {
            skipLocationUpdate.current = false;
            return;
        }

        const resolvedParams = resolveParams(paramSetup, location.pathname, state);

        // If params have changed, trigger a location update
        if (resolvedParams.toString() !== prevResolvedParams.current?.toString()) {
            // If we are on a bare route, assume we want to populate search params as a replace instead of a new location
            setSearchParams(resolvedParams, { replace: location.search === '' });
        }

        prevResolvedParams.current = resolvedParams;
    }, [state]);

    useEffect(() => {
        // If location.search is not empty, populate its values into the redux state
        if (location.search !== '') {
            const paramState = resolveState(paramSetup, location.pathname, searchParams);
            updateState(objectMerge(baseParamState, paramState));

            skipLocationUpdate.current = true;
        } else {
            // If location.search is empty, check if params should be populated
            const params = resolveParams(paramSetup, location.pathname, state);

            if (params.toString() !== '') {
                prevResolvedParams.current = params;

                // Replace the bare navigation in history with the full location
                setSearchParams(params, { replace: true });

                // Do not update location again based on this repopulation
                skipLocationUpdate.current = true;
            }
        }
    }, [location]);

    return null;
};

export default RouterStateSync;
