import { createContext, useContext, useEffect, useState } from 'react';
import { Quill } from 'react-quill';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import MagicUrl from 'quill-magic-url';

import { FlyoverPanel } from '@labradorsports/components';
import { PlaybookTabs } from '@labradorsports/constants';
import { useSite } from '@labradorsports/utils';

import { PlayEditor } from '../../../play-editor/index.js';
import { PlaybookHeader, PlayList } from '../../../playbook/index.js';
import { playbookActions, Selectors, useTeamPlaybookQuery } from '../../../store/index.js';
import { useDispatcher } from '../../hooks/index.js';

import createPlayLinkBlot from './play-link-blot.js';
import RosterPlayer, { RosterPlayerBlot } from './roster-player-blot.js';

Quill.register('formats/roster-player', RosterPlayerBlot);
Quill.register('modules/rosterPlayer', RosterPlayer);

Quill.register('modules/magicUrl', MagicUrl);

type AddPlayLinkHandler = (play: { playId: string; name: string; teamId: string }) => void;

export interface ContentEditorContextValue {
    addPlayLink: (handler: AddPlayLinkHandler) => void;
    showPlayPreview: (playId: string) => void;
}

export const ContentEditorContext = createContext<ContentEditorContextValue>(null);

export const useContentEditor = (): ContentEditorContextValue => {
    return useContext(ContentEditorContext);
};

const ContentEditorProvider: FC = ({ children }) => {
    const { Config } = useSite();
    const openPlay = useDispatcher(playbookActions.OpenPlay);
    const setShownPlaybook = useDispatcher(playbookActions.SetShownPlaybook);
    const {
        data: { shared: teamPlaylist },
    } = useTeamPlaybookQuery();
    const activeTeam = useSelector(Selectors.activeTeam);

    const [playLinkHandler, setPlayLinkHandler] = useState(null);
    const [showingPlayPreview, setShowingPlayPreview] = useState(false);

    useEffect(() => {
        const PlayLinkBlot = createPlayLinkBlot(Config);
        Quill.register('formats/play-link', PlayLinkBlot);
    }, []);

    const cancelAddPlayLink = () => {
        setPlayLinkHandler(null);
    };

    const confirmAddPlayLink = (playId: any) => {
        if (playLinkHandler) {
            const play = teamPlaylist.find((pl) => pl.id === playId);
            playLinkHandler({
                playId: play.id,
                name: play.name,
                teamId: activeTeam,
            });
        }

        setPlayLinkHandler(null);
    };

    const addPlayLink = (handler: AddPlayLinkHandler) => {
        setShownPlaybook(PlaybookTabs.TEAM);
        setPlayLinkHandler(() => handler);
    };

    const showPlayPreview = async (playId: string) => {
        openPlay(playId);

        setShowingPlayPreview(true);
    };

    const hidePlayPreview = () => {
        setShowingPlayPreview(false);
    };

    const value: ContentEditorContextValue = {
        addPlayLink,
        showPlayPreview,
    };

    return (
        <ContentEditorContext.Provider value={value}>
            <FlyoverPanel
                in={Boolean(playLinkHandler)}
                header={<PlaybookHeader title="Team Playbook" isTeam />}
                buttons={
                    <Button className="text-end" onClick={cancelAddPlayLink}>
                        Cancel
                    </Button>
                }
                aboveModals
            >
                <div className="p-2 pb-0">Select the play you would like to add:</div>
                <PlayList loadPlay={confirmAddPlayLink} playbookType={PlaybookTabs.TEAM} readOnly />
            </FlyoverPanel>
            {showingPlayPreview ? <PlayEditor readOnly forceFullscreen exitFullscreen={hidePlayPreview} /> : null}
            {children}
        </ContentEditorContext.Provider>
    );
};

export default ContentEditorProvider;
