import { ToolTypes, Sites } from '@labradorsports/constants';
import { getSiteForSport, objectMerge, createException, PlayEditorErrors } from '@labradorsports/utils';

import { FieldPiece, FieldViewport, Field, Play, Move, FrameSnapshot } from '../models/index.js';

import { applyOffset, getPresetCoords, getShadingCorners } from './geometry.js';
import { updateExistingOrCreateNew } from './misc.js';

export interface PlayData {
    initial: any[];
    balls: any[];
    frames: any[];
    id: string;
    name: string;
    fieldSetup: boolean;
    viewOptions: any;
    sport: string;
    type: string;
    tags: string[];
    description: string;
    fieldType: string;
    fieldConfig?: any;
    fieldPieces: any[];
    viewers?: any;
    version?: number;
    createdDate?: Date;
    lastUpdated?: Date;
    videoLink?: string;
    unifiedField?: boolean;
    branding?: {
        logoEntityId: string;
        colorPrimary: string;
        colorSecondary: string;
    };
    playbookId?: string;
    programId?: string;
}

export function doesPlayerTouchBall(playerId: number, balls: any[], frames: any[]): boolean {
    const hasInitialBall = balls.map((ball) => ball.carrier);

    return (
        hasInitialBall.some((id) => id === playerId) ||
        frames.some((frame) => {
            return frame.passes?.some((pass: any) => pass.target === playerId);
        })
    );
}

export function doesPieceTouchBall(players: any[], balls: any[], frames: any[], piece: FieldPiece): boolean {
    if (
        piece.type === ToolTypes.BLUEPLAYER ||
        piece.type === ToolTypes.ORANGEPLAYER ||
        piece.type === ToolTypes.COACH
    ) {
        return doesPlayerTouchBall(piece.id, balls, frames);
    }

    const piecePlayers = players.filter((plr) => plr.props?.pieceId === piece.id);

    return (
        piecePlayers.some((plr) => doesPlayerTouchBall(plr.id, balls, frames)) ||
        frames.some((frame) => {
            return frame.shots?.find((shot: any) => shot.target === piece.id);
        })
    );
}

export function initializePlay(PlayConfig: PlayConfigSpec, playData: any): Play {
    const { PlayTypes, FieldTypes, FieldSettings, GoalDesign } = PlayConfig;

    const { sport, fieldType, fieldConfig, type, fieldPieces, unifiedField } = playData;

    const goalDesign = GoalDesign[sport];

    if (fieldType) {
        const defaultConfig = FieldSettings[sport][unifiedField ? PlayTypes.FULL_FIELD : fieldType];

        if (!defaultConfig && !fieldConfig) {
            throw createException(PlayEditorErrors.INVALID_FIELD_CONFIG, {
                details: `sport: ${sport} fieldType: ${fieldType} playData: ${JSON.stringify(playData)}`,
            });
        }

        const { width, height, sideline } =
            type === PlayTypes.DRILL && fieldType === FieldTypes.CUSTOM ? fieldConfig : defaultConfig;

        const field = new Field(width, height, sideline, {
            fieldType,
            pieces: fieldPieces,
            goalDesign,
        });

        return new Play(PlayConfig, field, playData);
    }

    return new Play(PlayConfig, null, playData);
}

export function createDefaultGoals(PlayConfig: PlayConfigSpec, play: any) {
    const { FieldTypes, FieldSettings } = PlayConfig;

    if (play.fieldType === FieldTypes.CUSTOM) {
        return { pieces: [], goalies: [] };
    }

    const { goals = [], height } = FieldSettings[play.sport]?.[play.fieldType] ?? {};

    const pieces = goals.map((goal: any, idx) => ({
        ...goal,
        type: ToolTypes.GOAL,
        id: idx,
        origin: applyOffset(goal.origin, {
            y: PlayConfig.FeatureFlags.UnifiedField && play.fieldType === PlayConfig.FieldTypes.HALF_FIELD ? height : 0,
        }),
    }));

    const goalies = pieces.map((goal) => ({
        type: goal.goalieType,
        pieceId: goal.id,
        id: goal.id,
        origin: goal.origin,
    }));

    return { pieces, goalies };
}

export function createDefaultBalls(PlayConfig: PlayConfigSpec, play: any) {
    if (PlayConfig.NewPlaySettings.InitialBalls) {
        return PlayConfig.NewPlaySettings.InitialBalls.map((ballDef, idx) => {
            const player = play.initial.find((plr) => plr.role === ballDef.role);

            return {
                id: idx,
                carrier: player.id,
            };
        });
    }

    return [];
}

export function createDefaultPlayers(PlayConfig: PlayConfigSpec, play: any): any[] {
    const { NewPlaySettings, FieldTypes, FieldSettings, MoveConfig } = PlayConfig;

    if (NewPlaySettings.StartingFormation) {
        const { layout } = MoveConfig[NewPlaySettings.StartingFormation];
        const { width, height } =
            play.fieldType === FieldTypes.CUSTOM ? play.fieldConfig : FieldSettings[play.sport][play.fieldType];

        return getPresetCoords(layout, { x: width / 2, y: height / 2 }, width).map((coords, idx) => {
            return {
                id: idx,
                origin: coords,
                type: layout[idx].type,
                ...(layout[idx].props ?? {}),
            };
        });
    }

    return [];
}

export function getFieldViewport(players: any[], moves: any[]) {
    let minX = 99999;
    let minY = 99999;
    let maxX = 0;
    let maxY = 0;

    const checkBounds = (item: any) => {
        if (isNaN(item.x) || isNaN(item.y)) {
            return;
        }

        maxX = Math.max(maxX, item.x);
        maxY = Math.max(maxY, item.y);

        minX = Math.min(minX, item.x);
        minY = Math.min(minY, item.y);
    };

    players.forEach((plr) => checkBounds(plr.origin));

    moves.forEach((mv: any) => {
        mv.anchors.forEach(checkBounds);
    });

    const width = maxX - minX;
    const height = maxY - minY;
    const padding = Math.max(height * 0.2, 3);

    return {
        top: minY - padding,
        left: minX - padding,
        width: width + padding * 2,
        height: height + padding * 2,
    };
}

export function createLines(move: Move) {
    return move.anchors.map((anc, idx) => {
        const a = move.anchors[idx - 1] ?? move.origin;

        return {
            a: {
                x: a.x,
                y: a.y,
            },
            b: {
                x: anc.x,
                y: anc.y,
            },
            move,
        };
    });
}

export function convertToUnifiedField(PlayConfig: PlayConfigSpec, play: PlayData) {
    const { PlayTypes, FieldSettings } = PlayConfig;

    const newPlay = {
        ...play,
        unifiedField: true,
    };

    if (play.fieldType !== PlayTypes.HALF_FIELD) {
        return newPlay;
    }

    const fieldSettings = FieldSettings[play.sport][PlayTypes.HALF_FIELD];
    const offset = { x: 0, y: fieldSettings.height };

    newPlay.initial = newPlay.initial.map((plr: any) => ({
        ...plr,
        origin: applyOffset(plr.origin, offset),
    }));

    if (newPlay.fieldPieces) {
        newPlay.fieldPieces = newPlay.fieldPieces.map((piece) => ({
            ...piece,
            origin: applyOffset(piece.origin, offset),
        }));
    }

    newPlay.frames = newPlay.frames.map((frm: any) => {
        const newFrame = { ...frm };

        if (newFrame.passes) {
            newFrame.passes = newFrame.passes.map((pass) =>
                pass.anchors
                    ? {
                          ...pass,
                          anchors: pass.anchors.map((anc) => ({ ...anc, ...applyOffset(anc, offset) })),
                      }
                    : pass
            );
        }

        if (newFrame.shots) {
            newFrame.shots = newFrame.shots.map((shot) =>
                shot.anchors
                    ? {
                          ...shot,
                          anchors: shot.anchors.map((anc) => ({ ...anc, ...applyOffset(anc, offset) })),
                      }
                    : shot
            );
        }

        if (newFrame.moves) {
            newFrame.moves = newFrame.moves.map((move) =>
                move.anchors
                    ? {
                          ...move,
                          anchors: move.anchors.map((anc) => ({ ...anc, ...applyOffset(anc, offset) })),
                      }
                    : move
            );
        }

        if (newFrame.fieldComments) {
            newFrame.fieldComments = newFrame.fieldComments.map((comment) => ({
                ...comment,
                ...applyOffset(comment, offset),
            }));
        }

        return newFrame;
    });

    return newPlay;
}

// Mutates arguments
export function removePlayerFromFrames(frames: any[], removed: any) {
    frames.forEach((frame: any) => {
        if (frame.passes) {
            frame.passes = frame.passes.filter((pass: any) => pass.target !== removed.id);
        }

        if (frame.shots) {
            frame.shots = frame.shots.filter((shot: any) => shot.target === removed.pieceId);
        }

        if (frame.moves) {
            frame.moves = frame.moves.filter(
                (mv: any) =>
                    mv.target !== removed.id && (mv.targetLine === undefined || mv.targetLine !== removed.pieceId)
            );
        }

        if (frame.assignments) {
            frame.assignments = frame.assignments.filter(
                (asn: any) => asn.defId !== removed.id && asn.atkId !== removed.id
            );
        }

        if (frame.slides) {
            frame.slides = frame.slides.filter((sld: any) => sld.target !== removed.id);
        }

        if (frame.options) {
            if (!removed.hasBall) {
                frame.options = frame.options.filter((opt: any) => opt !== removed.id);
            } else {
                frame.options = [];
            }
        }

        if (frame.playerRotations) {
            frame.playerRotations = frame.playerRotations.filter((rot: any) => rot.target !== removed.id);
        }
    });
}

// Mutates arguments
export function removeBallMovement(play: any, ballId: number) {
    play.balls = play.balls.filter((ball: any) => ball.id !== ballId);

    play.frames.forEach((frame: any) => {
        if (frame.passes) {
            frame.passes = frame.passes.filter((pass: any) => pass.ball !== ballId);
        }

        if (frame.shots) {
            frame.shots = frame.shots.filter((shot: any) => shot.ball !== ballId);
        }
    });
}

export function getEmptyFrame() {
    return {
        moves: [] as any[],
    };
}

export function getDefaultViewOptions(PlayConfig: PlayConfigSpec) {
    return objectMerge(
        {
            ATTACK: {
                moves: true,
                roles: true,
                ids: true,
            },
            DEFENSE: {
                moves: true,
                roles: true,
                ids: true,
                assignments: true,
            },
            flipField: false,
            fieldLines: {},
        },
        PlayConfig.NewPlaySettings.ViewOptions ?? {}
    );
}

export function getNewPlay(PlayConfig: PlayConfigSpec, params: any) {
    const { PlayTypes, FeatureFlags, NewPlaySettings } = PlayConfig;
    const { type, ...playDetails } = params;

    const play: PlayData = {
        ...playDetails,
        version: 9,
        type,
        fieldSetup: true,
        fieldType: null,
        unifiedField: FeatureFlags.UnifiedField,
        balls: [],
        initial: [],
        frames: new Array(NewPlaySettings.FrameCount).fill(null).map(() => getEmptyFrame()),
        viewOptions: getDefaultViewOptions(PlayConfig),
    };

    if (type !== PlayTypes.DRILL) {
        play.fieldType = type;

        const { pieces, goalies } = createDefaultGoals(PlayConfig, play);
        play.fieldPieces = pieces;
        play.initial = goalies.concat(createDefaultPlayers(PlayConfig, play));

        play.balls = createDefaultBalls(PlayConfig, play);
    }

    return play;
}

export function getFrameDuration(sport: string, frame: FrameSnapshot): number {
    const site = getSiteForSport(sport);

    if (site === Sites.footballlab) {
        if (frame.idx === 1) {
            return 0.25;
        }

        if (frame.moves?.length === 0) {
            return 0.25;
        }
    }

    return frame.config.DynamicAnimation ? frame.longestMoveLength / 20 : 1;
}

export function getFieldConfig(PlayConfig: PlayConfigSpec, play: PlayData) {
    const { PlayTypes, FieldTypes, FieldSettings } = PlayConfig;
    const { sport, fieldType, fieldConfig, type, unifiedField } = play;

    // Custom field config
    if (type === PlayTypes.DRILL && fieldType === FieldTypes.CUSTOM) {
        return fieldConfig;
    }

    if (
        // Legacy field
        !unifiedField ||
        // full field
        fieldType === PlayTypes.FULL_FIELD
    ) {
        return FieldSettings[sport][fieldType];
    }

    const halfFieldConfig = FieldSettings[sport][PlayTypes.HALF_FIELD];
    const fullFieldConfig = FieldSettings[sport][PlayTypes.FULL_FIELD];

    const piecePositions: Point[] = [];

    play.initial.forEach((plr) => piecePositions.push(plr.origin));
    play.frames.forEach((frame) => {
        if (frame.moves) {
            frame.moves.forEach((move) => move.anchors?.forEach((anc) => piecePositions.push(anc)));
        }

        if (frame.passes) {
            frame.passes.forEach((pass) => pass.anchors?.some((anc) => piecePositions.push(anc)));
        }

        if (frame.shots) {
            frame.shots.forEach((shot) => shot.anchors?.some((anc) => piecePositions.push(anc)));
        }

        if (frame.fieldComments) {
            frame.fieldComments.forEach((comment) => piecePositions.push(comment));
        }
    });

    const useFullField = piecePositions.some((pos) => pos.y < fullFieldConfig.center.y);

    return useFullField ? fullFieldConfig : halfFieldConfig;
}

export function conformPlayData(PlayConfig: PlayConfigSpec, play: any) {
    const newPlay = { ...play };

    newPlay.initial = newPlay.initial ?? [];
    newPlay.frames = newPlay.frames ?? [];

    if (!newPlay.version) {
        newPlay.version = 2;

        // Properly handle plays which were saved with the old flag name
        newPlay.fieldSetup = Boolean(newPlay.fieldSetup || newPlay.addingInitial);
        delete newPlay.addingInitial;

        if (newPlay.type !== PlayConfig.PlayTypes.DRILL) {
            newPlay.fieldType = newPlay.type;
        }

        // Ensure that field type and goals will always be set (all plays bfor non-drill plays
        const { pieces, goalies } = createDefaultGoals(PlayConfig, newPlay);
        newPlay.fieldPieces = pieces;

        newPlay.initial = newPlay.initial.map((plr: any) => {
            if (plr.type === ToolTypes.ATTACKER) return { ...plr, type: ToolTypes.BLUEPLAYER };
            if (plr.type === ToolTypes.DEFENDER) return { ...plr, type: ToolTypes.ORANGEPLAYER };
            return plr;
        });

        const [defaultGoalie, secondGoalie] = goalies;

        const existingGoalie = newPlay.initial.find((plr: any) => plr.type === ToolTypes.GOALIE);

        // If there is already a goalie, reuse the same id
        newPlay.initial = newPlay.initial.filter((plr: any) => plr.type !== ToolTypes.GOALIE);

        updateExistingOrCreateNew(newPlay.initial, {
            ...defaultGoalie,
            id: existingGoalie ? existingGoalie.id : undefined,
        });

        if (secondGoalie) {
            // Secondary goalie can always get a new id
            updateExistingOrCreateNew(newPlay.initial, {
                ...secondGoalie,
                id: undefined,
            });
        }

        // Ensure that the shoot goes to the primary goal by default
        newPlay.frames = newPlay.frames.map((frm: any) => {
            if (typeof frm.shoot === 'boolean') {
                return { ...frm, shoot: 0 };
            }

            return frm;
        });

        if (!newPlay.viewOptions) {
            newPlay.viewOptions = getDefaultViewOptions(PlayConfig);
        }
    }

    if (newPlay.version === 2) {
        newPlay.version = 3;

        newPlay.frames = newPlay.frames.map((frm: any) => {
            if (typeof frm.pass === 'number') {
                return {
                    ...frm,
                    pass: {
                        type: ToolTypes.PASS,
                        target: frm.pass,
                    },
                };
            }

            return frm;
        });
    }

    if (newPlay.version === 3) {
        newPlay.version = 4;

        newPlay.frames = newPlay.frames.map((frm: any) => {
            return {
                ...frm,
                moves: frm.moves.map((mv: any) => {
                    if (mv.type === PlayConfig.ToolTypes.DODGE || mv.type === PlayConfig.ToolTypes.ROLLBACK) {
                        return {
                            ...mv,
                            type: ToolTypes.OMOVE,
                        };
                    }

                    return mv;
                }),
            };
        });
    }

    if (newPlay.version === 4) {
        newPlay.version = 5;

        newPlay.frames = newPlay.frames.map((frm: any) => {
            const newFrame = {
                ...frm,
            };

            if (newFrame.options) {
                newFrame.options = newFrame.options.map((target: number) => ({
                    type: ToolTypes.OPTION,
                    id: target,
                    target,
                }));
            }

            if (newFrame.shoot !== undefined) {
                newFrame.shoot = {
                    target: newFrame.shoot,
                    type: ToolTypes.SHOOT,
                };
            }

            return newFrame;
        });
    }

    if (newPlay.version === 5) {
        newPlay.version = 6;

        const ballCarrier = newPlay.initial.find((plr: any) => plr.hasBall);

        newPlay.initial = newPlay.initial.map((plr: any) => {
            const newPlr = { ...plr };
            delete newPlr.hasBall;

            return newPlr;
        });

        newPlay.balls = [];

        if (ballCarrier) {
            newPlay.balls.push({
                id: 0,
                carrier: ballCarrier.id,
            });

            // We need to track the holder of the ball to assign source to old options
            let currentHolder = ballCarrier.id;

            newPlay.frames = newPlay.frames.map((frm: any) => {
                const newFrame = {
                    ...frm,
                };

                newFrame.moves = newFrame.moves.map((mv: any) => {
                    if (mv.type === ToolTypes.OMOVE || mv.type === ToolTypes.DMOVE) {
                        return {
                            ...mv,
                            type: ToolTypes.MOVE,
                        };
                    }

                    return mv;
                });

                if (newFrame.options) {
                    newFrame.options = newFrame.options.map((opt: any) => ({
                        ...opt,
                        source: currentHolder,
                    }));
                }

                if (newFrame.pass) {
                    newFrame.passes = [
                        {
                            ...newFrame.pass,
                            ball: 0,
                        },
                    ];

                    currentHolder = newFrame.pass.target;

                    delete newFrame.pass;
                }

                if (newFrame.shoot) {
                    newFrame.shots = [
                        {
                            ...newFrame.shoot,
                            ball: 0,
                        },
                    ];

                    const goalie = play.pieces
                        ? play.initial.find((plr: any) => {
                              if (typeof plr.pieceId === 'number') {
                                  const piece = play.pieces.find((pc: any) => plr.pieceId === pc.id);

                                  return piece.type === ToolTypes.GOAL && piece.id === newFrame.shoot.target;
                              }

                              return false;
                          })
                        : null;

                    if (goalie) {
                        currentHolder = goalie.id;
                    }

                    delete newFrame.shoot;
                }

                return newFrame;
            });
        }
    }

    if (newPlay.version === 6) {
        newPlay.version = 7;

        newPlay.initial = newPlay.initial.map((plr: any) => {
            const newPlr = {
                origin: {
                    x: plr.x,
                    y: plr.y,
                },
                ...plr,
            };

            delete newPlr.x;
            delete newPlr.y;

            return newPlr;
        });
    }

    if (newPlay.version === 7) {
        newPlay.version = 8;

        newPlay.frames = newPlay.frames.map((frm: any) => {
            const newFrame = {
                ...frm,
            };

            if (newFrame.fieldComments) {
                newFrame.fieldComments = newFrame.fieldComments.map((comment) => ({
                    ...comment,
                    width: 150,
                    height: 50,
                }));
            }

            return newFrame;
        });
    }

    if (newPlay.version === 8) {
        newPlay.version = 9;

        Object.assign(newPlay, convertToUnifiedField(PlayConfig, newPlay));
    }

    // Protect against version mismatch (newer play features with older code)
    if (newPlay.initial) {
        newPlay.initial = newPlay.initial.filter((plr: any) => plr.type in PlayConfig.ToolTypes);
    }

    if (newPlay.frames) {
        newPlay.frames = newPlay.frames.map((frame: any) => {
            const newFrame = {
                ...frame,
            };

            if (newFrame.passes) {
                newFrame.passes = newFrame.passes.filter((pass: any) => pass.type in PlayConfig.ToolTypes);
            }

            if (newFrame.shots) {
                newFrame.shots = newFrame.shots.filter((shot: any) => shot.type in PlayConfig.ToolTypes);
            }

            if (newFrame.moves) {
                newFrame.moves = newFrame.moves.filter((mv: any) => mv.type in PlayConfig.ToolTypes);
            }

            return newFrame;
        });
    }

    if (newPlay.pieces) {
        newPlay.pieces = newPlay.pieces.filter((piece: any) => piece.type in PlayConfig.ToolTypes);
    }

    return newPlay;
}

export function conformFormationData(PlayConfig: PlayConfigSpec, formation: any) {
    const newFormation = { ...formation };

    if (!formation.version) {
        formation.version = 7;

        newFormation.initial.map((plr: any) => {
            const newPlr = { ...plr };
            newPlr.origin = {
                x: plr.x,
                y: plr.y,
            };

            delete newPlr.x;
            delete newPlr.y;

            return newPlr;
        });
    }

    // Protect against version mismatch (newer play features with older code)
    if (newFormation.initial) {
        newFormation.initial = newFormation.initial.filter((plr: any) => plr.type in PlayConfig.ToolTypes);
    }

    if (newFormation.pieces) {
        newFormation.pieces = newFormation.pieces.filter((piece: any) => piece.type in PlayConfig.ToolTypes);
    }

    return newFormation;
}

export function getFormationAnchor(
    PlayConfig: PlayConfigSpec,
    playData: PlayData,
    anchorType: string,
    pieces: any[] = []
) {
    const { FieldTypes, FieldSettings } = PlayConfig;
    const { fieldType, fieldConfig = null, sport } = playData;

    if (fieldType === FieldTypes.CUSTOM) {
        return {
            x: fieldConfig.width / 2,
            y: fieldConfig.height / 2,
        };
    }

    const goals = pieces.filter((piece) => piece.type === ToolTypes.GOAL);

    if (anchorType === ToolTypes.GOAL && goals.length === 0) {
        return FieldSettings[sport][fieldType]?.center;
    }

    return goals[0].origin;
}

export function getZoomStyles(
    fieldViewport: FieldViewport,
    zoomOffset: ScreenPoint,
    dragAdjustment: Point = { x: 0, y: 0 }
) {
    if (!fieldViewport || !zoomOffset) {
        return {};
    }

    return {
        width: `${fieldViewport.zoomFactor * 100}%`,
        height: `${fieldViewport.zoomFactor * 100}%`,
        left: -(zoomOffset.x + dragAdjustment.x),
        top: -(zoomOffset.y + dragAdjustment.y),
    };
}

export function getShadingColor(color: string): string {
    const isTransparent = color?.length === 9 && !color.toLowerCase().endsWith('ff');

    return isTransparent ? color : `${color.slice(0, 7)}38`;
}
