import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

import { Plans } from '@labradorsports/constants';
import { isProgramPaid, safeToDate } from '@labradorsports/utils';

import firestore from '../../firebase-config.js';
import { DataTags } from '../../shared/constants.js';
import { trackUserProperties } from '../../shared/utils.js';
import { createApiSlice } from '../api.js';
import { billingActions, mainActions } from '../index.js';
import { CustomMutationDefinition, CustomQueryDefinition } from '../types.js';

export const billingApi = createApiSlice({
    // Retrieve billing entities for programs this user is admin of
    getAdminBilling: {
        type: 'query',
        query: () => ({
            path: 'billing/getAdminBilling',
        }),
        providesTags: (results) =>
            results
                ? Object.keys(results).map((entityId) => ({ type: DataTags.BILLING_ENTITY, id: entityId }))
                : [{ type: DataTags.BILLING_ENTITY, id: 'LIST' }],
    } as CustomQueryDefinition<void, any>,

    fetchBillingDetails: {
        type: 'query',
        queryFn: async (arg, { dispatch, getState, extra }) => {
            const state = getState();
            const { logger } = extra;
            const { uid } = state.auth.user;

            const bdRef = doc(firestore, 'billingDetails', uid);

            const [bdDoc, billingEntities, paymentMethods, programAdminBD] = await Promise.all([
                getDoc(bdRef),
                getDocs(collection(bdRef, 'billingEntities')),
                getDocs(collection(bdRef, 'paymentMethods')),
                dispatch(billingApi.endpoints.getAdminBilling.initiate(null)),
            ]);

            logger.log('fetchBillingDetails billingDetails fetch complete');

            const bd = bdDoc.data();

            const objectify = (query: any) =>
                Object.fromEntries(
                    query.docs.map((queryDoc: any) => [
                        queryDoc.id,
                        {
                            ...queryDoc.data(),
                            id: queryDoc.id,
                        },
                    ])
                );

            const fixDates = ([id, team]: [string, any]) => [
                id,
                {
                    ...team,
                    paymentExpires: safeToDate(team.paymentExpires) ?? null,
                    lastPayment: safeToDate(team.lastPayment) ?? null,
                    createdDate: safeToDate(team.createdDate) ?? new Date(0),
                },
            ];

            const addIsPaid = ([id, team]: [string, any]) => [
                id,
                {
                    ...team,
                    paid: isProgramPaid(team.paymentExpires)[0],
                },
            ];

            const adminPrograms = Object.fromEntries(
                Object.entries(programAdminBD.data ?? {})
                    .map(fixDates)
                    .map(addIsPaid)
            );

            const teams = {
                ...adminPrograms,
                ...Object.fromEntries(
                    Object.entries(objectify(billingEntities))
                        .filter(([k]) => k !== Plans.PERSONAL_UNLIMITED)
                        .map(fixDates)
                        .map(addIsPaid)
                ),
            };

            if (Object.entries(teams).length > 0) {
                const oldestProgram: any = Object.entries(teams).sort(
                    ([k1, v1]: any[], [k2, v2]: any[]) => v1.createdDate?.getTime() - v2.createdDate?.getTime()
                )[0][1];

                trackUserProperties(uid, {
                    programName: oldestProgram.name,
                    programCreated: oldestProgram.createdDate,
                    lastPayment: oldestProgram.lastPayment,
                });
            }

            const cards = objectify(paymentMethods);

            return {
                data: {
                    billingDetails: bd,
                    teams,
                    cards,
                },
            };
        },
        providesTags: ({ billingDetails, teams, cards }) => {
            const entityTags = Object.keys(teams).map((teamId) => ({ type: DataTags.BILLING_ENTITY, id: teamId }));
            const cardTags = Object.keys(cards).map((cardId) => ({ type: DataTags.PAYMENT_METHOD, id: cardId }));

            return [
                ...entityTags,
                ...cardTags,
                { type: DataTags.BILLING_ENTITY, id: 'LIST' },
                { type: DataTags.PAYMENT_METHOD, id: 'LIST' },
                { type: DataTags.BILLING_DETAILS, id: billingDetails?.id },
            ];
        },
    } as CustomQueryDefinition<void, any>,

    disableSubscription: {
        type: 'mutation',
        query: ({ entityId }) => ({
            path: 'billing/disableSubscription',
            body: {
                entityId,
            },
        }),
        invalidatesTags: (result, meta, { entityId }) => [
            { type: DataTags.BILLING_ENTITY, id: entityId },
            { type: DataTags.OWNED_PROGRAM, id: entityId },
        ],
    } as CustomMutationDefinition<{ entityId: string }, any>,

    resumeSubscription: {
        type: 'mutation',
        query: ({ entityId, email }) => ({
            path: 'billing/resumeSubscription',
            body: {
                entityId,
                email,
            },
        }),
        invalidatesTags: (result, meta, { entityId }) => [
            { type: DataTags.BILLING_ENTITY, id: entityId },
            { type: DataTags.OWNED_PROGRAM, id: entityId },
        ],
    } as CustomMutationDefinition<{ entityId: string; email?: string }, any>,

    updateBillingInfo: {
        type: 'mutation',
        // nameOnCard serves no purpose to the server
        query: ({ entityId, updatedInfo: { nameOnCard: _, ...updates } }) => ({
            path: 'billing/updateBillingInfo',
            body: {
                entityId,
                updates,
            },
        }),
        invalidatesTags: (result, meta, { entityId }) => [
            { type: DataTags.BILLING_ENTITY, id: entityId },
            { type: DataTags.OWNED_PROGRAM, id: entityId },
        ],
    } as CustomMutationDefinition<{ entityId: string; updatedInfo: any }, any>,

    previewBillingUpdate: {
        type: 'query',
        query: ({ entityId, updatedInfo }) => ({
            path: 'billing/previewBillingUpdate',
            query: {
                entityId,
                updates: JSON.stringify(updatedInfo),
            },
        }),
        transformResponse: (result) => {
            return {
                ...result,
                nextPaymentDate: result.nextPaymentDate ? new Date(result.nextPaymentDate) : undefined,
            };
        },
    } as CustomQueryDefinition<{ entityId: string; updatedInfo: any }, any>,

    triggerInvoice: {
        type: 'mutation',
        query: ({ programId, email }) => ({
            path: 'billing/triggerInvoice',
            body: { programId, email },
        }),
        onQueryEnded: (response, { dispatch }) => {
            dispatch(mainActions.GenericAlert('Invoice triggered'));
        },
    } as CustomMutationDefinition<{ programId: string; email: string }, any>,

    validateDiscountCodes: {
        type: 'query',
        extraOptions: {
            suppressError: true,
        },
        query: ({ couponCode }) => ({
            path: 'billing/validateDiscountCode',
            query: { code: couponCode },
        }),
        transformResponse: (response) => {
            return {
                coupon: response,
            };
        },
        onQueryEnded: ({ data: result }, { dispatch }) => {
            const updates: any = {};

            if (result.coupon.success) {
                updates.couponCode = result.coupon.code;
                updates.couponProperties = result.coupon.properties;
            } else if (!result.coupon.error) {
                updates.couponCode = '';
                updates.couponProperties = null;
            }

            dispatch(billingActions.UpdateCreatingBilling(updates));
        },
    } as CustomQueryDefinition<{ couponCode: string }, any>,

    getInvoices: {
        type: 'query',
        query: () => ({ path: 'billing/getInvoices' }),
        transformResponse: ({ entityInvoices }) => {
            return entityInvoices.map((invoice: any) => ({
                ...invoice,
                date: new Date(invoice.date),
                lines: invoice.lines.map((line: any) => ({
                    ...line,
                    period: {
                        start: new Date(line.period.start),
                        end: new Date(line.period.end),
                    },
                })),
            }));
        },
        providesTags: (invoices) => invoices.map((invoice) => ({ type: DataTags.INVOICE, id: invoice.id })),
    } as CustomQueryDefinition<void, any>,

    addPersonalUnlimited: {
        type: 'mutation',
        query: ({ settings }) => ({
            path: 'billing/addPersonalUnlimited',
            body: settings,
        }),
        invalidatesTags: () => [{ type: DataTags.BILLING_ENTITY, id: Plans.PERSONAL_UNLIMITED }],
    } as CustomMutationDefinition<{ settings: any }, any>,

    getPersonalUnlimited: {
        type: 'query',
        queryFn: async (arg, { getState }) => {
            const state = getState();
            const { uid } = state.auth.user;
            const bdSnap = await getDoc(
                doc(firestore, 'billingDetails', uid, 'billingEntities', Plans.PERSONAL_UNLIMITED)
            );

            if (bdSnap.exists()) {
                const bd = bdSnap.data();
                return {
                    data: {
                        ...bd,
                        paymentExpires: safeToDate(bd.paymentExpires),
                        lastPayment: safeToDate(bd.lastPayment),
                        createdDate: safeToDate(bd.createdDate),
                    },
                };
            }

            return {
                data: undefined,
            };
        },
        onQueryEnded: ({ data: bd }, { getState }) => {
            const state = getState();
            const { uid } = state.auth.user;

            if (bd && bd.createdDate) {
                trackUserProperties(uid, {
                    personalCreated: safeToDate(bd.createdDate),
                    personalLastPayment: safeToDate(bd.lastPayment),
                });
            }
        },
        providesTags: () => [{ type: DataTags.BILLING_ENTITY, id: Plans.PERSONAL_UNLIMITED }],
    } as CustomQueryDefinition<void, any>,

    addPaymentMethod: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['billingAddress'],
            suppressError: true,
        },
        query: ({ cardToken, billingAddress }) => ({
            path: 'billing/addPaymentMethod',
            body: {
                cardToken,
                billingAddress,
            },
        }),
        invalidatesTags: (result) => (result?.error ? [] : [{ type: DataTags.PAYMENT_METHOD, id: 'LIST' }]),
    } as CustomMutationDefinition<{ cardToken: string; billingAddress: any }, any>,

    deletePaymentMethod: {
        type: 'mutation',
        query: ({ cardId, cancelSubscriptions = false }) => ({
            path: 'billing/deletePaymentMethod',
            body: {
                cardId,
                cancelSubscriptions,
            },
        }),
        invalidatesTags: () => [{ type: DataTags.PAYMENT_METHOD, id: 'LIST' }],
    } as CustomMutationDefinition<{ cardId: string; cancelSubscriptions: boolean }, any>,

    retryPayment: {
        type: 'mutation',
        query: ({ entityId }) => ({
            path: 'billing/retryPayment',
            body: {
                entityId,
            },
        }),
        invalidatesTags: (result, meta, { entityId }) => [{ type: DataTags.BILLING_ENTITY, id: entityId }],
    } as CustomMutationDefinition<{ entityId: string }, any>,

    loadSuccessfulInvoice: {
        type: 'query',
        query: ({ linkId }) => ({
            path: 'billing/loadSuccessfulInvoice',
            query: {
                linkId,
            },
        }),
    } as CustomQueryDefinition<{ linkId: string }, any>,
});

export const {
    useGetAdminBillingQuery,
    useFetchBillingDetailsQuery,
    useDisableSubscriptionMutation,
    useResumeSubscriptionMutation,
    useUpdateBillingInfoMutation,
    usePreviewBillingUpdateQuery,
    useTriggerInvoiceMutation,
    useValidateDiscountCodesQuery,
    useGetInvoicesQuery,
    useAddPersonalUnlimitedMutation,
    useGetPersonalUnlimitedQuery,
    useAddPaymentMethodMutation,
    useDeletePaymentMethodMutation,
    useRetryPaymentMutation,
    useLoadSuccessfulInvoiceQuery,
} = billingApi;
