import { useEffect, ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

import { useDispatcher } from '../../shared/hooks/index.js';
import { authActions, mainActions, RootState, Selectors, LoginPopoverForms } from '../../store/index.js';

const FormPaths: any = {
    '/signup': LoginPopoverForms.SIGNUP,
    '/login': LoginPopoverForms.LOGIN,
    '/forgot-password': LoginPopoverForms.FORGOT_PASSWORD,
};

interface Props {
    FormComponent: ComponentType<any>;
}

const AccountManagementForm: FC<Props> = ({ FormComponent }) => {
    const setDeeplink = useDispatcher(mainActions.SetDeeplink);
    const setAccountFormError = useDispatcher(authActions.AccountFormError);
    const user = useSelector(Selectors.user);
    const deeplink = useSelector((state: RootState) => state.main.deeplink);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        return () => {
            setDeeplink();
        };
    }, []);

    useEffect(() => {
        setAccountFormError();
    }, [location.pathname]);

    useEffect(() => {
        if (user) {
            // TODO: figure out how to properly deeplink on native
            if (!APP && deeplink) {
                // Using window.location instead of history to avoid issues with router-state-sync picking up url params
                window.location.replace(decodeURIComponent(deeplink));
            } else {
                navigate('/feed');
            }
        }
    }, [user, deeplink, navigate]);

    const changeActiveForm = (form: string) => {
        Object.entries(FormPaths).forEach(([key, value]) => {
            if (form === value) {
                navigate(key);
            }
        });
    };

    return (
        <div className="account-management-form text-center mt-5 mx-3">
            <FormComponent changeForm={changeActiveForm} />
        </div>
    );
};

export default AccountManagementForm;
