import { doc, updateDoc, getDoc, deleteDoc, serverTimestamp, addDoc, collection } from 'firebase/firestore';

import { PlaybookTabs, ShareLevels } from '@labradorsports/constants';
import { conformFormationData } from '@labradorsports/play-rendering';
import { getFolder, getFolderParent, safeToDate } from '@labradorsports/utils';

import { downloadFile } from '../../app/plugins/file.js';
import firestore from '../../firebase-config.js';
import { DataTags, UnsavedPlayMessage } from '../../shared/constants.js';
import Logger from '../../shared/logger.js';
import { createApiSlice } from '../api.js';
import { mainActions } from '../main/index.js';
import { playEditorActions } from '../play-editor/index.js';
import { RootState, Selectors } from '../state.js';
import { CustomMutationDefinition, CustomQueryDefinition, TD } from '../types.js';

import { playbookActions } from './index.js';

const checkOpenPlay = (plays: any[], shared: any[]) => {
    return (dispatch: TD, getState: () => RootState): void => {
        const state = getState();

        // If the currently opened play is saved and does not exist in the new playlist, close it
        if (state.playEditor.play) {
            const foundShared = shared?.find((pl: any) => pl.id === state.playEditor.play.id);

            const found = plays?.find((pl: any) => pl.id === state.playEditor.play.id);

            if (
                !found &&
                plays &&
                !foundShared &&
                shared &&
                !state.playEditor.play.id.includes('__') &&
                (!state.community.playId || !state.community.playId.includes(state.playEditor.play?.id)) &&
                !state.playbook.publicLinkId
            ) {
                dispatch(playEditorActions.PlayLoaded());
            }
        }
    };
};

function playbookTags(playbook: any, backupId: string) {
    const listTags = [
        { type: DataTags.PLAYLIST_ITEM, id: `${backupId}_LIST` },
        { type: DataTags.FORMATION, id: `${backupId}_LIST` },
        { type: DataTags.FOLDER, id: `${backupId}_LIST` },
    ];

    if (!playbook) {
        return listTags;
    }

    const pbListTags = [
        { type: DataTags.PLAYLIST_ITEM, id: `${playbook.id}_LIST` },
        { type: DataTags.FORMATION, id: `${playbook.id}_LIST` },
        { type: DataTags.FOLDER, id: `${playbook.id}_LIST` },
    ];

    const playTags = (playbook.plays ?? playbook.shared).map((play) => ({
        type: DataTags.PLAYLIST_ITEM,
        id: `${playbook.id}_${play.id}`,
    }));
    const formationTags = playbook.formations.map((formation) => ({
        type: DataTags.FORMATION,
        id: `${playbook.id}_${formation.id}`,
    }));
    const folderTags = playbook.folders.map((folder) => ({ type: DataTags.FOLDER, id: `${playbook.id}_${folder.id}` }));

    return [...playTags, ...formationTags, ...folderTags, ...listTags, ...pbListTags];
}

const processPlay = (logger: Logger, play: any, playId: string) => {
    if (!play) return null;

    const newPlay = {
        ...play,
        id: playId,
    };

    const logIfBad = (date) => logger.log('bad play date', { playId, date });

    if (play.createdDate) {
        newPlay.createdDate = safeToDate(play.createdDate, logIfBad);
    }

    if (play.lastUpdated) {
        newPlay.lastUpdated = safeToDate(play.lastUpdated, logIfBad);
    }

    if (play.viewers) {
        newPlay.viewers = Object.entries(play.viewers).reduce(
            (accumulator, [k, v]: [string, any]) => ({
                ...accumulator,
                [k]: {
                    ...v,
                    lastViewed: typeof v.lastViewed === 'string' ? new Date(v.lastViewed) : v.lastViewed.toDate(),
                },
            }),
            {}
        );
    }

    return newPlay;
};

export const playbookApi = createApiSlice({
    loadMyPlaybook: {
        type: 'query',
        query: (arg, { extra }) => ({
            meta: {
                site: extra.site,
            },
            path: 'playbook/getMyPlaybook',
        }),
        transformResponse: (response, { site }) => {
            const playlist = response.plays.map((pl: any) => ({
                ...pl,
                createdDate: new Date(pl.createdDate),
                lastUpdated: new Date(pl.lastUpdated),
            }));

            const formations = response.formations.map((formation) =>
                conformFormationData(site.PlayConfig, {
                    ...formation,
                    createdDate: new Date(formation.createdDate),
                })
            );

            return {
                ...response,
                plays: playlist,
                formations,
            };
        },
        onQueryEnded: ({ data: playbook }, { dispatch, extra, getState }) => {
            const state = getState();
            const { logger } = extra;
            const { shownPlaybook, folderId } = state.playbook;
            const { plays, formations, folders } = playbook;
            const teamPlaybook = Selectors.teamPlaybook(state);

            logger.log('loadMyPlaybook complete', {
                plays: plays?.length,
                formations: formations?.length,
                folders: folders?.length,
                id: playbook.id,
            });

            dispatch(checkOpenPlay(playbook.plays, teamPlaybook?.shared));

            if (folders && shownPlaybook === PlaybookTabs.MY && folderId) {
                const currentFolder = getFolder(folders, folderId);
                if (!currentFolder) {
                    dispatch(playbookActions.OpenPlayFolder(null));
                }
            }
        },
        providesTags: (result) => {
            return playbookTags(result, 'MY');
        },
    } as CustomQueryDefinition<void, any>,

    loadTeamPlaybook: {
        type: 'query',
        isValid: ({ activeTeam }) => Boolean(activeTeam),
        query: ({ activeTeam }, { extra }) => {
            const { site } = extra;

            return {
                path: 'playbook/getTeamPlaybook',
                query: { teamId: activeTeam },
                meta: { site },
            };
        },
        transformResponse: (response, { site }) => {
            const shared = response.playbook.shared?.map((pl: any) => ({
                ...pl,
                createdDate: pl.createdDate && new Date(pl.createdDate),
                sharedOn: pl.sharedOn && new Date(pl.sharedOn),
            }));

            const formations = response.playbook.formations.map((formation) =>
                conformFormationData(site.PlayConfig, {
                    ...formation,
                    createdDate: new Date(formation.createdDate),
                })
            );

            return {
                id: response.id,
                ...response.playbook,
                shared,
                formations,
            };
        },
        onQueryEnded: ({ data: playbook }, { dispatch, getState, extra }) => {
            const state = getState();
            const { logger } = extra;
            const { shownPlaybook, folderId } = state.playbook;
            const { shared, formations, folders, id } = playbook;
            const myPlaybook = Selectors.myPlaybook(state);

            logger.log('loadTeamPlaybook complete', {
                id,
                plays: shared?.length,
                formations: formations?.length,
                folders: folders?.length,
            });

            dispatch(checkOpenPlay(myPlaybook?.plays, shared));

            if (folders && shownPlaybook === PlaybookTabs.TEAM && folderId) {
                const currentFolder = getFolder(folders, folderId);
                if (!currentFolder) {
                    dispatch(playbookActions.OpenPlayFolder(null));
                }
            }
        },
        providesTags: (result: any, error, { activeTeam }) => {
            return playbookTags(result, activeTeam);
        },
    } as CustomQueryDefinition<{ activeTeam: string }, any>,

    loadViewedPlays: {
        type: 'query',
        isValid: ({ activeTeam }) => Boolean(activeTeam),
        query: ({ activeTeam, folderId }) => ({
            path: 'playbook/getViewedPlays',
            query: {
                teamId: activeTeam,
                folderId,
            },
        }),
    } as CustomQueryDefinition<{ activeTeam: string; folderId: string }, any>,

    loadMyPlay: {
        type: 'query',
        isValid: ({ playbookId, playId }) => Boolean(playbookId && playId),
        queryFn: async ({ playbookId, playId }, { extra }) => {
            const { logger } = extra;

            const snapshot = await getDoc(doc(firestore, 'playbooks', playbookId, 'plays', playId));

            logger.log('loadPlay complete');

            const play = processPlay(
                logger,
                {
                    ...snapshot.data(),
                    createdDate: '2025-02-20T00:46:23.942Z',
                },
                playId
            );

            return { data: play };
        },
        providesTags: (result, error, { playbookId, playId }) => [
            { type: DataTags.PLAY, id: `${playbookId}_${playId}` },
        ],
    } as CustomQueryDefinition<{ playbookId: string; playId: string }, any>,

    loadSharedPlay: {
        type: 'query',
        query: ({ teamId, srcPlaybookId, playId }) => {
            return {
                path: 'playbook/getSharedPlay',
                query: {
                    teamId,
                    srcPlaybookId,
                    playId,
                },
            };
        },
        transformResponse: ({ play }) => play,
        providesTags: (result, error, { srcPlaybookId, playId }) => [
            { type: DataTags.PLAY, id: `${srcPlaybookId}_${playId}` },
        ],
    } as CustomQueryDefinition<{ teamId: string; srcPlaybookId: string; playId: string }, any>,

    deletePlay: {
        type: 'mutation',
        queryFn: async ({ playbookId, playId }, { getState, extra, dispatch }) => {
            const state = getState();
            const { logger } = extra;
            const { play } = state.playEditor;

            if (play?.id === playId) {
                dispatch(playEditorActions.PlayLoaded());
            }

            if (!playId?.includes('__')) {
                logger.log('deletePlay existing', { playbookId, playId });
                // Existing play

                if (playId) {
                    logger.log('deletePlay existing update playlist complete');
                    await deleteDoc(doc(firestore, 'playbooks', playbookId, 'plays', playId));

                    logger.log('deletePlay existing delete play complete');
                } else {
                    logger.log('deletePlay play without id');
                }
            }
        },
        onQueryEnded: async (result, { extra, getState, dispatch }, { playbookId, playId }) => {
            const { logger } = extra;
            const state = getState();
            const myPlaybook = Selectors.myPlaybook(state);
            const teamPlaybook = Selectors.teamPlaybook(state);
            const { folders } = [myPlaybook, teamPlaybook].find((pb) => pb.id === playbookId);

            await Promise.all(
                folders
                    .filter((f) => f.plays.includes(playId))
                    // This should filter down to one folder but just in case we clear all folders containing this play
                    .map(async (folder) => {
                        logger.log('updating containing folder', { id: folder.id });

                        await dispatch(
                            playbookApi.endpoints.saveFolder.initiate({
                                playbookId,
                                folder: {
                                    ...folder,
                                    plays: folder.plays.filter((p) => p !== playId),
                                },
                            })
                        );
                    })
            );
        },
        invalidatesTags: (result, error, { playbookId }) => [
            { type: DataTags.PLAYLIST_ITEM, id: `${playbookId}_LIST` },
        ],
    } as CustomMutationDefinition<{ playbookId: string; playId: string }, any>,

    movePlaybookItems: {
        type: 'mutation',
        query: ({ changes, folderId, playbookId }) => ({
            path: 'playbook/movePlaybookItems',
            body: {
                playbookId,
                changes,
                folderId,
            },
        }),
        invalidatesTags: (result, error, { changes, folderId, playbookId }) => {
            return [
                { type: DataTags.FOLDER, id: `${playbookId}_${folderId}` },
                ...Object.keys(changes).flatMap((k) => [
                    { type: DataTags.FOLDER, id: `${playbookId}_${k}` },
                    { type: DataTags.PLAYLIST_ITEM, id: `${playbookId}_${k}` },
                    { type: DataTags.PLAY, id: `${playbookId}_${k}` },
                ]),
            ];
        },
    } as CustomMutationDefinition<{ changes: any; folderId?: string; playbookId: string }, any>,

    saveFolder: {
        type: 'mutation',
        isValid: ({ playbookId, folder }) => Boolean(playbookId && folder),
        extraOptions: {
            unloggableArg: ['folder'],
        },
        queryFn: async ({ playbookId, folder }) => {
            const newFolder: any = {
                ...folder,
            };

            if (folder.id) {
                await updateDoc(doc(firestore, 'playbooks', playbookId, 'folders', folder.id), newFolder);
            } else {
                const created = await addDoc(collection(firestore, 'playbooks', playbookId, 'folders'), newFolder);
                return {
                    data: created.id,
                };
            }

            return {
                data: '',
            };
        },
        invalidatesTags: (result, error, { playbookId, folder }) => [
            { type: DataTags.FOLDER, id: `${playbookId}_${folder.id ?? 'LIST'}` },
        ],
    } as CustomMutationDefinition<{ playbookId: string; folder: Folder }, any>,

    deleteFolder: {
        type: 'mutation',
        isValid: ({ playbookId, folderId }) => Boolean(playbookId && folderId),
        queryFn: async ({ playbookId, folderId }) => {
            await deleteDoc(doc(firestore, 'playbooks', playbookId, 'folders', folderId));
        },
        onQueryEnded: async (result, { extra, getState, dispatch }, { folderId, playbookId }) => {
            const { logger } = extra;
            const state = getState();
            const myPlaybook = Selectors.myPlaybook(state);
            const teamPlaybook = Selectors.teamPlaybook(state);
            const { folders } = [myPlaybook, teamPlaybook].find((pb) => pb.id === playbookId);

            const deletedFolder = getFolder(folders, folderId);
            const parentFolder = getFolderParent(folders, folderId);

            if (parentFolder) {
                logger.log('updating parent folder', { id: parentFolder.id });

                await dispatch(
                    playbookApi.endpoints.saveFolder.initiate({
                        playbookId,
                        folder: {
                            ...parentFolder,
                            folders: parentFolder.folders
                                .filter((f) => f !== folderId)
                                .concat(deletedFolder.folders ?? []),
                            plays: parentFolder.plays.concat(deletedFolder.plays ?? []),
                        },
                    })
                );
            }
        },
        invalidatesTags: (result, error, { playbookId }) => [{ type: DataTags.FOLDER, id: `${playbookId}_LIST` }],
    } as CustomMutationDefinition<{ playbookId: string; folderId: string }, any>,

    saveSharedPlay: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['play'],
            suppressLoading: true,
        },
        isValid: ({ playbookId, activeTeam, play }) => Boolean(playbookId && activeTeam && play?.id),
        query: ({ playbookId, activeTeam, play }) => ({
            path: 'playbook/saveSharedPlay',
            body: {
                play,
                teamId: activeTeam,
                srcPlaybookId: playbookId,
            },
        }),
        onQueryStarted: ({ play }, { extra }) => {
            const { logger } = extra;
            logger.log('saveSharedPlay play id', { id: play.id });
        },
        onQueryEnded: (result, { dispatch }, { teamId, srcPlaybookId, play }) => {
            // We update the query data directly to prevent a loading indicator for the loadSharedPlay query
            dispatch(
                playbookApi.util.updateQueryData(
                    'loadSharedPlay',
                    {
                        teamId,
                        srcPlaybookId,
                        playId: play.id,
                    },
                    () => play
                )
            );
            dispatch(playEditorActions.PlayModified(false));
        },
    } as CustomMutationDefinition<{ playbookId: string; activeTeam: string; play: any }, any>,

    saveExistingPlay: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['play'],
            suppressLoading: true,
        },
        isValid: ({ playbookId, play }) => Boolean(playbookId && play?.id),
        queryFn: async ({ playbookId, play }, { extra }) => {
            const { logger } = extra;
            logger.log('saveExistingPlay play id', { id: play.id });

            const { lastUpdated, createdDate, sharedOn, ...playData } = play;

            await updateDoc(doc(firestore, 'playbooks', playbookId, 'plays', play.id), {
                ...playData,
                lastUpdated: serverTimestamp(),
            });
        },
        onQueryEnded: (result, { dispatch }, { playbookId, play }) => {
            // We update the query data directly to prevent a loading indicator for the loadMyPlay query
            dispatch(playbookApi.util.updateQueryData('loadMyPlay', { playbookId, playId: play.id }, () => play));
            dispatch(playEditorActions.PlayModified(false));
        },
    } as CustomMutationDefinition<{ playbookId: string; play: any }, any>,

    saveNewPlay: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['play'],
            suppressLoading: true,
        },
        query: ({ play, playbookId }) => ({
            path: 'playbook/saveNewPlay',
            body: {
                play,
                playbookId,
            },
        }),
        onQueryEnded: ({ data: { playlistItem } }, { dispatch, extra }) => {
            const { logger } = extra;
            logger.log('saveNewPlay complete', { id: playlistItem.id });

            dispatch(playEditorActions.NewPlaySaved(playlistItem.id));
        },
        invalidatesTags: (result, error, { playbookId }) => [
            { type: DataTags.PLAYLIST_ITEM, id: `${playbookId}_LIST` },
        ],
    } as CustomMutationDefinition<{ playbookId: string; play: any }, any>,

    shareItems: {
        type: 'mutation',
        query: ({ itemIds, modifications, proxyTeamId, srcPlaybookId }) => ({
            path: 'playbook/shareItems',
            body: {
                srcPlaybookId,
                modifications,
                itemIds,
                proxyTeamId,
            },
        }),
        invalidatesTags: (result, error, { modifications }) =>
            Object.keys(modifications).flatMap((k) => [
                { type: DataTags.PLAYLIST_ITEM, id: `${k}_LIST` },
                { type: DataTags.FOLDER, id: `${k}_LIST` },
            ]),
        onQueryEnded: (result, { dispatch }, { modifications, activeTeam }) => {
            if (modifications?.[activeTeam] && modifications[activeTeam] !== ShareLevels.NOONE) {
                dispatch(playbookActions.SetShownPlaybook(PlaybookTabs.TEAM));
            }
        },
    } as CustomMutationDefinition<
        { itemIds: string[]; modifications: any; proxyTeamId?: string; srcPlaybookId: string },
        any
    >,

    loadViewerDetails: {
        type: 'query',
        query: ({ activeTeam, srcPlaybookId, playId }) => ({
            path: 'playbook/getViewerDetails',
            query: {
                teamId: activeTeam,
                srcPlaybookId,
                playId,
            },
        }),
    } as CustomQueryDefinition<{ activeTeam: string; srcPlaybookId: string; playId: string }, any>,

    savePlayMetadata: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['name', 'description'],
        },
        query: ({ playbookId, playId, name, tags, description }) => ({
            path: 'playbook/updatePlayMetadata',
            body: {
                playbookId,
                playId,
                name,
                tags,
                description,
            },
        }),
        invalidatesTags: (result, error, { playbookId, playId }) => [
            { type: DataTags.PLAY, id: `${playbookId}_${playId}` },
            { type: DataTags.PLAYLIST_ITEM, id: `${playbookId}_${playId}` },
        ],
    } as CustomMutationDefinition<
        { playbookId: string; playId: string; name: string; tags: string[]; description: string },
        any
    >,

    copyPlay: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['copyName'],
        },
        query: ({ playbookId, playId, copyName, flipHorizontal, sourcePlaybookId }) => ({
            path: 'playbook/copyPlay',
            body: {
                playbookId,
                playId,
                sourcePlaybookId,
                copyName,
                flipHorizontal,
            },
        }),
        onQueryEnded: ({ data: { playlistItem } }: any, { dispatch }) => {
            dispatch(playbookActions.SetShownPlaybook('my'));
            dispatch(playbookActions.OpenPlay(playlistItem.id));
        },
        invalidatesTags: (result, error, { playbookId }) => [
            { type: DataTags.PLAYLIST_ITEM, id: `${playbookId}_LIST` },
        ],
    } as CustomMutationDefinition<
        { playbookId: string; playId: string; copyName: string; flipHorizontal: boolean; sourcePlaybookId: string },
        any
    >,

    sendStudyReminder: {
        type: 'mutation',
        query: ({ plays, activeTeam }) => ({
            path: 'playbook/sendStudyReminder',
            body: {
                plays,
                teamId: activeTeam,
            },
        }),
        onQueryEnded: ({ data: result }, { dispatch }) => {
            if (result.success) {
                dispatch(mainActions.GenericAlert('Your team has been notified.'));
            } else {
                dispatch(mainActions.GenericAlert('There are no users joined to your team.'));
            }
        },
    } as CustomMutationDefinition<{ plays: string[]; activeTeam: string }, any>,

    getSharedStatus: {
        type: 'query',
        query: ({ playId }, { getState }) => {
            const state = getState();
            const teams = Selectors.editableTeams(state);

            return {
                path: 'playbook/getSharedStatus',
                query: {
                    playId,
                    teamIds: teams.map((tm) => tm.id),
                },
            };
        },
    } as CustomQueryDefinition<{ playId: string }, any>,

    getPublicShareLink: {
        type: 'query',
        query: ({ playId, playbookId, teamId }) => ({
            path: 'playbook/getPublicShareLink',
            query: {
                playId,
                playbookId,
                teamId,
            },
        }),
        transformResponse: ({ link }) => link,
    } as CustomQueryDefinition<{ playId: string; playbookId: string; teamId: string }, any>,

    getPublicSharedPlay: {
        type: 'query',
        extraOptions: {
            anonymousHooks: true,
        },
        isValid: ({ publicLinkId }) => Boolean(publicLinkId),
        query: ({ publicLinkId }) => ({
            path: 'playbook/getPublicSharedPlay',
            query: {
                linkId: publicLinkId,
            },
        }),
        onQueryEnded: async ({ data: result }, { dispatch }) => {
            if (result?.success === false) {
                dispatch(playbookActions.PublicLoadPlayError(true));
            }
        },
    } as CustomQueryDefinition<{ publicLinkId: string }, any>,

    checkGIFStatus: {
        type: 'query',
        extraOptions: {
            anonymousHooks: true,
            suppressLoading: true,
        },
        isValid: (arg) => Boolean(arg),
        query: ({ playbookId, playId, programId, linkId }) => ({
            path: 'playbook/checkGIFStatus',
            query: {
                playbookId,
                playId,
                programId,
                linkId,
            },
        }),
        onQueryEnded: ({ data: result }: any, { dispatch, getState }, { playId, playbookId }) => {
            const state = getState();
            const { previewUrl, gifUrl } = result;
            const gifDownload = Selectors.gifDownload(state, playId, playbookId);

            dispatch(
                playbookActions.AddGIFDownload({
                    ...gifDownload,
                    previewUrl,
                    gifUrl,
                })
            );
        },
    } as CustomQueryDefinition<
        { playbookId: string; playId: string; programId: string; linkId?: string; gifDelay: number },
        any
    >,

    triggerGIFExport: {
        type: 'mutation',
        extraOptions: {
            anonymousHooks: true,
        },
        query: ({ playbookId, playId, programId, linkId }) => ({
            path: 'playbook/exportGIF',
            query: {
                playbookId,
                playId,
                programId,
                linkId,
            },
        }),
        onQueryEnded: (
            { data: { estimatedWait, nonce } }: any,
            { dispatch, extra },
            { playbookId, playId, programId, name, linkId }
        ) => {
            const { logger } = extra;

            logger.log('export triggered', { estimatedWait, nonce });

            dispatch(
                playbookActions.AddGIFDownload({
                    playbookId,
                    playId,
                    programId,
                    nonce,
                    estimatedWait,
                    name,
                    linkId,
                })
            );
        },
    } as CustomMutationDefinition<
        { playbookId: string; playId: string; programId: string; name: string; linkId?: string },
        any
    >,

    getViewershipProfile: {
        type: 'query',
        isValid: ({ activeTeam, personUID }) => Boolean(activeTeam && personUID),
        query: ({ activeTeam, personUID }) => ({
            path: 'playbook/getViewershipProfile',
            query: {
                teamId: activeTeam,
                personUID,
            },
        }),
        transformResponse: ({ viewed }) => {
            return viewed.map((play) => ({
                ...play,
                viewers: Object.fromEntries(
                    Object.entries(play.viewers).map(([k, v]: [string, any]) => [
                        k,
                        {
                            ...v,
                            lastViewed: new Date(v.lastViewed),
                        },
                    ])
                ),
            }));
        },
    } as CustomQueryDefinition<{ activeTeam: string; personUID: string }, any>,

    saveFormation: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['formation'],
        },
        queryFn: async ({ playbookId, formation }, { extra }) => {
            const { logger } = extra;

            if (formation.id) {
                logger.log('existingFormation', { id: formation.id });

                await updateDoc(doc(firestore, 'playbooks', playbookId, 'formations', formation.id), {
                    ...formation,
                    playbookId,
                });
            } else {
                logger.log('newFormation');

                const newFormation = {
                    ...formation,
                    createdDate: new Date(),
                };

                delete newFormation.id;
                delete newFormation.playbookId;

                await addDoc(collection(firestore, 'playbooks', playbookId, 'formations'), newFormation);
            }
        },
        invalidatesTags: (result, error, { playbookId, formation }) => [
            { type: DataTags.FORMATION, id: `${playbookId}_${formation.id ?? 'LIST'}` },
        ],
    } as CustomMutationDefinition<{ playbookId: string; formation: any }, any>,

    deleteFormation: {
        type: 'mutation',
        queryFn: async ({ playbookId, formationId }) => {
            await deleteDoc(doc(firestore, 'playbooks', playbookId, 'formations', formationId));
        },
        invalidatesTags: (result, error, { playbookId }) => [{ type: DataTags.FORMATION, id: `${playbookId}_LIST` }],
    } as CustomMutationDefinition<{ playbookId: string; formationId: string }, any>,

    searchFormations: {
        type: 'query',
        extraOptions: {
            unloggableArg: ['query'],
        },
        isValid: ({ query }) => query !== '',
        query: ({ playbookId, teamPlaybookId, query }) => ({
            path: 'search/formations',
            query: {
                query,
                playbookIds: [playbookId, teamPlaybookId].filter(Boolean),
            },
        }),
        transformResponse: (response) => response.results.hits,
    } as CustomQueryDefinition<{ playbookId: string; teamPlaybookId: string; query: string }, any>,

    downloadGIF: {
        type: 'mutation',
        extraOptions: {
            anonymousHooks: true,
        },
        queryFn: async ({ name, gifUrl }, { extra, dispatch }) => {
            const { logger } = extra;
            const filename = `${encodeURIComponent(name)}.gif`;
            const url = `${gifUrl}&download=true`;

            if (APP) {
                logger.log('native', { filename });
                const response = await fetch(url);
                const blob = await response.blob();
                logger.log('loaded blob');
                await downloadFile(logger, blob, filename);

                dispatch(mainActions.GenericAlert('The GIF was downloaded to your Files app.'));
            } else {
                logger.log('web', { filename });
                const response = await fetch(url);
                const blob = await response.blob();
                const objUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        },
    } as CustomMutationDefinition<{ name: string; gifUrl: string }, any>,

    confirmUnsaved: {
        type: 'mutation',
        extraOptions: {
            suppressLoading: true,
            suppressError: true,
        },
        queryFn: async (arg, { dispatch, getState }) => {
            const state = getState();

            if (state.playEditor.playModified && !Selectors.playReadOnly(state)) {
                const result: boolean = await new Promise((resolve) => {
                    const confirm = window.confirm(UnsavedPlayMessage);
                    if (confirm) {
                        dispatch(playEditorActions.PlayModified(false));
                        dispatch(playEditorActions.Saving(false));
                        resolve(true);
                        return;
                    }

                    resolve(false);
                    return;
                });

                return { data: result };
            }

            return { data: true };
        },
    } as CustomMutationDefinition<void, any>,
});

export const {
    useLoadMyPlaybookQuery,
    useMovePlaybookItemsMutation,
    useSaveFolderMutation,
    useDeleteFolderMutation,
    useLoadViewedPlaysQuery,
    useLoadTeamPlaybookQuery,
    useSaveSharedPlayMutation,
    useSaveExistingPlayMutation,
    useSaveNewPlayMutation,
    useLoadSharedPlayQuery,
    useLoadMyPlayQuery,
    useDeletePlayMutation,
    useShareItemsMutation,
    useLoadViewerDetailsQuery,
    useSavePlayMetadataMutation,
    useCopyPlayMutation,
    useSendStudyReminderMutation,
    useGetSharedStatusQuery,
    useGetPublicShareLinkQuery,
    useGetPublicSharedPlayQuery,
    useCheckGIFStatusQuery,
    useTriggerGIFExportMutation,
    useGetViewershipProfileQuery,
    useSaveFormationMutation,
    useDeleteFormationMutation,
    useSearchFormationsQuery,
    useDownloadGIFMutation,
    useConfirmUnsavedMutation,
} = playbookApi;
