import { addDoc, arrayUnion, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';

import { EventTypes } from '@labradorsports/constants';
import {
    GeneralErrors,
    createException,
    createNestedUpdates,
    getEventDate,
    getSearchTimeCutoff,
} from '@labradorsports/utils';

import firestore from '../../firebase-config.js';
import { DataTags } from '../../shared/constants.js';
import Logger from '../../shared/logger.js';
import { createApiSlice } from '../api.js';
import { mainActions } from '../main/index.js';
import { Selectors } from '../state.js';
import { CustomMutationDefinition, CustomQueryDefinition } from '../types.js';

import { scheduleActions } from './index.js';

const processEvent = (logger: Logger, event: any, eventId?: string) => {
    if (!event) return null;

    try {
        const newEvent = {
            ...event,
            id: eventId ?? event.id,
        };

        [
            'eventDate',
            'endTime',
            'eventDateUpdated',
            'locationUpdated',
            'objectivesUpdated',
            'opponentUpdated',
            'fieldDetailsUpdated',
            'createdDate',
        ].forEach((k) => {
            newEvent[k] = typeof event[k] === 'string' ? new Date(event[k]) : event[k]?.toDate();
        });

        if (event.reminder) {
            newEvent.reminder.sendAt =
                typeof event.reminder.sendAt === 'string'
                    ? new Date(event.reminder.sendAt)
                    : event.reminder.sendAt.toDate();
        }

        return newEvent;
    } catch (error) {
        logger.exception(createException(GeneralErrors.UNKNOWN_ERROR, { nestedError: error }));
        return null;
    }
};

export const scheduleApi = createApiSlice({
    loadSchedule: {
        type: 'query',
        isValid: ({ activeTeam }) => Boolean(activeTeam),
        queryFn: async ({ activeTeam }, { getState, extra }) => {
            const { logger } = extra;
            const state = getState();

            if (!Selectors.teamViewable(state)) return { data: [] };

            const cutoff = getSearchTimeCutoff();

            const eventDocs = await getDocs(
                query(collection(firestore, 'teams', activeTeam, 'schedule'), where('eventDate', '>=', cutoff))
            );

            const events = eventDocs.docs.map((eventDoc) => {
                return processEvent(logger, eventDoc.data(), eventDoc.id);
            });

            logger.log('loadSchedule complete', { events: events.length });

            return {
                data: events,
            };
        },
        onQueryEnded: ({ data: events }, { dispatch }) => {
            const usedLocations: any = events.reduce((accumulator: any, event: any) => {
                if (event.location) {
                    const placeId = event.location.place.id;
                    if (!accumulator[placeId]) {
                        accumulator[placeId] = {
                            location: event.location,
                            uses: 0,
                        };
                    }

                    accumulator[placeId].uses += 1;
                }

                return accumulator;
            }, {});

            dispatch(
                scheduleActions.FavoriteLocationsLoaded(
                    Object.values(usedLocations)
                        .sort((a: any, b: any) => b.uses - a.uses)
                        .slice(0, 3)
                        .map(({ location }: any) => location)
                )
            );
        },
        providesTags: (result: any[]) =>
            result
                ? [...result.map(({ id }) => ({ type: DataTags.EVENT, id })), { type: DataTags.EVENT, id: 'LIST' }]
                : [{ type: DataTags.EVENT, id: 'LIST' }],
    } as CustomQueryDefinition<{ activeTeam: string }, any>,

    saveExistingEvent: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['event'],
        },
        query: ({ event }, { getState, extra }) => {
            const { logger } = extra;
            const state = getState();
            const { activeTeam } = state.teams;

            const selector = scheduleApi.endpoints.loadSchedule.select({ activeTeam });
            const { data: events } = selector(state) as any;
            const idx = events.findIndex((evt: any) => evt.id === event.id);
            const existing = events[idx];
            const eventUpdate = { ...existing, ...event };

            if (typeof eventUpdate.endTime === 'string') {
                eventUpdate.endTime = getEventDate(eventUpdate.eventDate, eventUpdate.endTime);
            }

            const updated = new Date();

            if (existing.eventDate.getTime() !== event.eventDate.getTime() || existing.endTime !== event.endTime) {
                eventUpdate.eventDateUpdated = updated;
            }

            if (existing.location?.name !== event.location?.name) {
                eventUpdate.locationUpdated = updated;
            }

            if (existing.objectives !== event.objectives) {
                eventUpdate.objectivesUpdated = updated;
            }

            if (existing.fieldDetails !== event.fieldDetails) {
                eventUpdate.fieldDetailsUpdated = updated;
            }

            if (event.eventType === EventTypes.GAME) {
                if (existing.opponent !== event.opponent) {
                    eventUpdate.opponentUpdated = updated;
                }
            }

            Object.keys(eventUpdate).forEach((k) => {
                if (eventUpdate[k] === undefined) {
                    delete eventUpdate[k];
                }
            });

            if (!event.reminder) {
                eventUpdate.reminder = false;
            }

            logger.log('saveExistingEvent existing', { id: event.id });

            return {
                path: 'schedule/saveEvent',
                body: {
                    teamId: activeTeam,
                    eventId: event.id,
                    event: eventUpdate,
                },
            };
        },
        onQueryEnded: (result, { dispatch }, { event, autosave }) => {
            dispatch(scheduleActions.SetViewEvent(event.id));

            if (!autosave) {
                dispatch(mainActions.GenericAlert('Event changes saved'));
            }
        },
        invalidatesTags: (result, error, { event }) => [{ type: DataTags.EVENT, id: event.id }],
    } as CustomMutationDefinition<{ event: any; autosave?: boolean }, any>,

    updateRSVP: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['reason'],
        },
        isValid: ({ eventIds }) => eventIds.filter(Boolean).length > 0,
        query: ({ eventIds, status = '', reason = '' }, { getState }) => {
            const filteredEventIds = eventIds.filter(Boolean);
            const state = getState();
            const { activeTeam } = state.teams;

            return {
                path: 'schedule/updateRSVP',
                body: {
                    teamId: activeTeam,
                    eventIds: filteredEventIds,
                    status,
                    reason,
                },
            };
        },
        invalidatesTags: (result, error, { eventIds }) =>
            eventIds.filter(Boolean).map((eventId) => ({ type: DataTags.EVENT, id: eventId })),
    } as CustomMutationDefinition<{ eventIds: string[]; status?: string; reason?: string }, any>,

    // Using roster id instead of uid to allow attendance for un-registered players
    updateAttendance: {
        type: 'mutation',
        queryFn: async ({ activeTeam, eventId, id, attending }) => {
            await updateDoc(
                doc(firestore, 'teams', activeTeam, 'schedule', eventId),
                createNestedUpdates({
                    attendance: {
                        [id]: attending,
                    },
                })
            );
        },
        invalidatesTags: (result, error, { eventId }) => [{ type: DataTags.EVENT, id: eventId }],
    } as CustomMutationDefinition<
        {
            activeTeam: string;
            eventId: string;
            id: string;
            attending: boolean;
        },
        any
    >,

    getRSVPDetails: {
        type: 'query',
        isValid: ({ eventId, activeTeam }) => Boolean(eventId && activeTeam),
        query: ({ eventId, activeTeam }) => ({
            path: 'schedule/getRSVPDetails',
            query: {
                teamId: activeTeam,
                eventId,
            },
        }),
        transformResponse: ({ rsvpDetails }) => rsvpDetails,
    } as CustomQueryDefinition<{ eventId: string; activeTeam: string }, any>,

    saveNewEvents: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['eventDefs'],
        },
        query: ({ eventDefs }, { getState }) => {
            const state = getState();
            const { activeTeam: teamId } = state.teams;

            return {
                path: 'schedule/createBulkEvents',
                body: {
                    teamId,
                    eventDefs,
                },
            };
        },
        invalidatesTags: [{ type: DataTags.EVENT, id: 'LIST' }],
    } as CustomMutationDefinition<{ eventDefs: any[] }, any>,

    deleteScheduleEvent: {
        type: 'mutation',
        queryFn: async ({ eventId }, { getState }) => {
            const state = getState();
            const { activeTeam } = state.teams;

            await deleteDoc(doc(firestore, 'teams', activeTeam, 'schedule', eventId));
        },
        invalidatesTags: (result, error, { eventId }) => [{ type: DataTags.EVENT, id: eventId }],
    } as CustomMutationDefinition<{ eventId: string }, any>,

    saveBulkEvents: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['updates'],
        },
        query: ({ eventIds, updates, sendNotification }, { getState }) => {
            const state = getState();
            const { activeTeam: teamId } = state.teams;

            const updated = new Date();
            const changes = {
                ...updates,
            };

            if (updates.location) {
                changes.locationUpdated = updated;
            }

            if (updates.time) {
                changes.eventDateUpdated = updated;
            }

            return {
                path: 'schedule/editBulkEvents',
                body: {
                    teamId,
                    eventIds,
                    changes,
                    timezoneOffset: new Date().getTimezoneOffset(),
                    sendNotification,
                },
            };
        },
        invalidatesTags: (result, error, { eventIds }) =>
            eventIds.map((eventId) => ({ type: DataTags.EVENT, id: eventId })),
    } as CustomMutationDefinition<
        {
            eventIds: string[];
            updates: any;
            sendNotification: boolean;
        },
        any
    >,

    cancelBulkEvents: {
        type: 'mutation',
        query: ({ eventIds, deleteEvents, sendNotification }, { getState }) => {
            const state = getState();
            const { activeTeam: teamId } = state.teams;

            return {
                path: 'schedule/editBulkEvents',
                body: {
                    teamId,
                    eventIds,
                    changes: {
                        cancelled: true,
                    },
                    deleteEvents,
                    sendNotification,
                },
            };
        },
        invalidatesTags: (result, error, { eventIds }) =>
            eventIds.map((eventId) => ({ type: DataTags.EVENT, id: eventId })),
    } as CustomMutationDefinition<
        {
            eventIds: string[];
            deleteEvents: boolean;
            sendNotification: boolean;
        },
        any
    >,

    loadPracticeTemplates: {
        type: 'query',
        queryFn: async ({ activeTeam }, { getState }) => {
            const state = getState();

            if (!Selectors.teamEditable(state) || !Selectors.teamViewable(state)) {
                return {
                    data: [],
                };
            }

            const templateQuery = await getDocs(collection(firestore, 'teams', activeTeam, 'practiceTemplates'));

            const templates = templateQuery.docs.map((templateDoc) => {
                const template = templateDoc.data();

                return {
                    ...template,
                    id: templateDoc.id,
                    createdDate: template.createdDate.toDate(),
                } as any;
            });

            return {
                data: templates,
            };
        },
        providesTags: (result: any[]) =>
            result
                ? [
                      ...result.map(({ id }) => ({ type: DataTags.PRACTICE_TEMPLATE, id })),
                      { type: DataTags.PRACTICE_TEMPLATE, id: 'LIST' },
                  ]
                : [{ type: DataTags.PRACTICE_TEMPLATE, id: 'LIST' }],
    } as CustomQueryDefinition<{ activeTeam: string }, any>,

    savePracticeTemplate: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['template.sections'],
        },
        queryFn: async ({ template }, { getState, extra }) => {
            const { logger } = extra;
            const state = getState();
            const { uid } = state.auth.user;
            const { activeTeam } = state.teams;

            const templateCollection = await collection(firestore, 'teams', activeTeam, 'practiceTemplates');

            if (template.id) {
                logger.log('existing template', { id: template.id });

                await updateDoc(doc(templateCollection, template.id), template);
                logger.log('template saved');

                return {
                    data: {
                        template,
                    },
                };
            } else {
                logger.log('new template');

                const fullTemplate = {
                    ...template,
                    createdBy: uid,
                    createdDate: new Date(),
                };

                const templateRef = await addDoc(templateCollection, fullTemplate);
                logger.log('template saved', { id: templateRef.id });

                return {
                    data: {
                        template: { ...fullTemplate, id: templateRef.id },
                    },
                };
            }
        },
        invalidatesTags: (result, error, { template }) =>
            template.id
                ? [{ type: DataTags.PRACTICE_TEMPLATE, id: template.id }]
                : [{ type: DataTags.PRACTICE_TEMPLATE, id: 'LIST' }],
    } as CustomMutationDefinition<{ template: any; autosave?: boolean }, any>,

    deletePracticeTemplate: {
        type: 'mutation',
        queryFn: async ({ templateId }: { templateId: string }, { getState }) => {
            const state = getState();
            const { activeTeam } = state.teams;

            await deleteDoc(doc(firestore, 'teams', activeTeam, 'practiceTemplates', templateId));
        },
        invalidatesTags: (result, error, { templateId }) => [{ type: DataTags.PRACTICE_TEMPLATE, id: templateId }],
    },

    saveCustomEventType: {
        type: 'mutation',
        queryFn: async ({ activeTeam, name, color }, { getState }) => {
            const type = {
                name,
                color,
            };

            await updateDoc(doc(firestore, 'teams', activeTeam), {
                customEventTypes: arrayUnion(type),
            });

            return {
                data: type,
            };
        },
        invalidatesTags: (result, meta, { activeTeam }) => [{ type: DataTags.OWNED_TEAM, id: activeTeam }],
    } as CustomMutationDefinition<{ activeTeam: string; name: string; color: string }, any>,

    sendEventReminder: {
        type: 'mutation',

        query: ({ eventId, teamId }) => ({
            path: 'schedule/sendEventReminder',
            body: {
                teamId,
                eventId,
            },
        }),
        onQueryEnded: (arg, { dispatch }) => {
            dispatch(mainActions.GenericAlert('Notification sent successfully'));
        },
    } as CustomMutationDefinition<{ eventId: string; teamId: string }, any>,
});

export const {
    useLoadScheduleQuery,
    useSaveExistingEventMutation,
    useSaveCustomEventTypeMutation,
    useSaveBulkEventsMutation,
    useSaveNewEventsMutation,
    useCancelBulkEventsMutation,
    useGetRSVPDetailsQuery,
    useUpdateRSVPMutation,
    useUpdateAttendanceMutation,
    useLoadPracticeTemplatesQuery,
    useSavePracticeTemplateMutation,
    useDeletePracticeTemplateMutation,
    useDeleteScheduleEventMutation,
    useSendEventReminderMutation,
} = scheduleApi;
