import { ReactElement } from 'react';

import { ButtonsBar, Panel, Flyover } from '../index.js';

interface Props {
    header: ReactElement;
    buttons?: ReactElement;
    className?: string;
    in: boolean;
    position?: 'right' | 'bottom' | 'left';
    aboveModals?: boolean;
}

const FlyoverPanel: FC<Props> = ({
    in: animationIn,
    header,
    children,
    buttons,
    className,
    position = 'left',
    aboveModals,
}) => {
    return (
        <Flyover in={animationIn} position={position} aboveModals={aboveModals}>
            <Panel
                noPadding
                className={className}
                header={header}
                content={children}
                footer={<ButtonsBar>{buttons}</ButtonsBar>}
            />
        </Flyover>
    );
};

export default FlyoverPanel;
