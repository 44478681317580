import { createSearchParams, matchPath } from 'react-router';

import { objectGet, objectSet, passthrough } from './utils.js';

interface StateParamConfig<T> {
    stateKey: string;
    initialState: T;
    type?: string;
    options?: {
        serialize?: (value: T) => string;
        parse?: (value: string) => T;
    };
}

interface PathConfig {
    [path: string]: StateParamConfig<any>;
}

export interface UrlStateConfig {
    [path: string]: PathConfig | [PathConfig, UrlStateConfig];
}

export function resolveConfig(paramSetup: UrlStateConfig, pathname: string) {
    return Object.entries(paramSetup)
        .filter(([pathPattern]) => {
            return Boolean(matchPath(pathPattern, pathname));
        })
        .reduce(
            (acc, [, config]) => ({
                ...acc,
                ...config,
            }),
            {
                ...paramSetup['global'],
            }
        );
}

export function resolveParams(paramSetup: UrlStateConfig, pathname: string, state) {
    const resolvedConfig = resolveConfig(paramSetup, pathname);

    return createSearchParams(
        Object.fromEntries(
            Object.entries(resolvedConfig)
                .map(([param, config]) => {
                    const value = objectGet(state, config.stateKey);

                    const serialize = config.options?.serialize ?? passthrough;
                    return [param, typeof value !== 'undefined' ? serialize(value) : config.initialState];
                })
                .filter(([, value]) => Boolean(value))
        )
    );
}

// Allows nesting of path configs and carries parent config into children
export function processParamConfig(config: UrlStateConfig): UrlStateConfig {
    return Object.entries(config).reduce((accumulator, [path, pathConfig]) => {
        const returnVal: UrlStateConfig = {
            ...accumulator,
        };

        if (Array.isArray(pathConfig)) {
            const [parentConfig, children] = pathConfig;

            returnVal[path] = parentConfig;

            Object.assign(
                returnVal,
                Object.fromEntries(
                    Object.entries(processParamConfig(children)).map(([subPath, subConfig]) => [
                        `${path}/${subPath}`,
                        {
                            ...subConfig,
                            ...parentConfig,
                        },
                    ])
                )
            );
        } else {
            returnVal[path] = pathConfig;
        }

        return returnVal;
    }, {});
}

export function resolveState(paramSetup: UrlStateConfig, pathname: string, searchParams: URLSearchParams) {
    const resolvedConfig = resolveConfig(paramSetup, pathname);

    const paramState = {};

    searchParams.forEach((v: string, k: string) => {
        const config = resolvedConfig[k];

        if (config) {
            const parse = config.options?.parse ?? passthrough;
            objectSet(paramState, config.stateKey, parse(v));
        }
    });

    return paramState;
}
