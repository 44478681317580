import { Plans, PlaybookTabs, ScheduleTabs } from '@labradorsports/constants';
import { objectMerge, objectSet, processParamConfig } from '@labradorsports/utils';

// stateKey should match the state path in RootState
// empty config objects ensure that the parent config is passed to the child paths
export const paramSetup = processParamConfig({
    global: {
        t: {
            stateKey: 'teams.activeTeam',
            initialState: '',
        },
    },
    '/login': {
        d: { stateKey: 'main.deeplink', initialState: '' },
    },
    '/signup': {
        d: { stateKey: 'main.deeplink', initialState: '' },
    },
    '/payment/*': {
        l: {
            stateKey: 'billing.invoiceLinkId',
            initialState: '',
        },
        e: {
            stateKey: 'billing.invoiceError',
            initialState: '',
        },
    },
    '/playbook': [
        {},
        {
            '*': {
                p: {
                    stateKey: 'playbook.playId',
                    initialState: '',
                },
                pb: {
                    stateKey: 'playbook.playbookId',
                    initialState: '',
                },
                sp: {
                    stateKey: 'playbook.shownPlaybook',
                    initialState: PlaybookTabs.MY,
                    options: {
                        parse: (value: string): string => {
                            // Only 3 valid values
                            if (value === PlaybookTabs.TEAM || value === PlaybookTabs.PUBLIC) return value;

                            return PlaybookTabs.MY;
                        },
                    },
                },
                f: {
                    stateKey: 'playbook.folderId',
                    initialState: '',
                },
                cpg: {
                    stateKey: 'community.pageNumber',
                    initialState: 1,
                    options: {
                        parse: Number,
                    },
                },
            },
            'usage/': {
                vd: {
                    stateKey: 'playbook.viewerDetailsPlayId',
                    initialState: '',
                },
            },
        },
    ],
    '/team/player/*': {
        p: {
            stateKey: 'teams.rosterPlayerId',
            initialState: '',
        },
    },
    '/join-team': {
        k: { stateKey: 'main.joinTeamCode', initialState: '' },
        j: { stateKey: 'main.joinTeamId', initialState: '' },
    },
    '/email-action': {
        mode: { stateKey: 'auth.emailAction.mode', initialState: '' },
        oobCode: { stateKey: 'auth.emailAction.oobCode', initialState: '' },
        apiKey: { stateKey: 'auth.emailAction.apiKey', initialState: '' },
        continueUrl: {
            stateKey: 'auth.emailAction.continueUrl',
            initialState: '',
        },
    },
    '/profile/teamadmin': [
        {},
        {
            'create/': {
                plan: {
                    stateKey: 'billing.creatingBilling.plan',
                    initialState: '',
                    options: {
                        parse: (val: string): string => {
                            if (val in Plans) {
                                return val;
                            }

                            return '';
                        },
                    },
                },
            },
            'edit/*': {
                e: {
                    stateKey: 'billing.editBillingEntityId',
                    initialState: '',
                },
            },
            'billing/': {
                e: {
                    stateKey: 'billing.editBillingEntityId',
                    initialState: '',
                },
            },
        },
    ],
    '/profile/billing/team': {
        e: {
            stateKey: 'billing.editBillingEntityId',
            initialState: '',
        },
    },
    '/schedule': [
        {
            st: {
                stateKey: 'schedule.shownTab',
                initialState: ScheduleTabs.FEED,
                options: {
                    parse: (value: string): string => {
                        // Only 2 valid values
                        if (value === ScheduleTabs.PRACTICE_TEMPLATES) return value;

                        return ScheduleTabs.FEED;
                    },
                },
            },
        },
        {
            'series/*': {},
            event: [
                {
                    ev: {
                        stateKey: 'schedule.viewEventId',
                        initialState: '',
                    },
                },
                {
                    '*': {},
                    'edit/*': {},
                    'scouting-report/*': {},
                },
            ],
            'practice/*': {
                p: {
                    stateKey: 'schedule.viewPracticeTemplateId',
                    initialState: '',
                },
            },
            scouting: [
                {
                    ev: {
                        stateKey: 'schedule.viewEventId',
                        initialState: '',
                    },
                },
                {
                    view: {},
                    'edit/*': {},
                },
            ],
        },
    ],
    '/schedule/update-rsvp': {
        r: {
            stateKey: 'schedule.viewEventId',
            initialState: '',
        },
        s: {
            stateKey: 'schedule.rsvpUpdateStatus',
            initialState: '',
        },
    },
    '/feed/post': {
        p: {
            stateKey: 'messaging.viewPostId',
            initialState: '',
        },
    },
    '/feed/documents/acks': {
        d: {
            stateKey: 'messaging.viewDocumentAcksId',
            initialState: '',
        },
    },
    '/play/*': {
        l: {
            stateKey: 'playbook.publicLinkId',
            initialState: '',
        },
    },
    '/community/latest': {
        pg: {
            stateKey: 'community.pageNumber',
            initialState: 1,
            options: {
                parse: Number,
            },
        },
    },
    '/community/popular': {
        pg: {
            stateKey: 'community.pageNumber',
            initialState: 1,
            options: {
                parse: Number,
            },
        },
    },
    '/community/trending': {
        pg: {
            stateKey: 'community.pageNumber',
            initialState: 1,
            options: {
                parse: Number,
            },
        },
    },
    '/community/play': {
        p: {
            stateKey: 'community.playId',
            initialState: '',
        },
    },
    '/community/profile': {
        pr: {
            stateKey: 'community.profileId',
            initialState: '',
        },
    },
    '/community/search': {
        q: {
            stateKey: 'community.searchText',
            initialState: '',
        },
        pg: {
            stateKey: 'community.pageNumber',
            initialState: 1,
            options: {
                parse: Number,
            },
        },
    },
});

export const baseParamState = Object.values(paramSetup).reduce((acc, pathConfig) => {
    const routeState = {};

    Object.values(pathConfig).forEach((paramConfig) => {
        objectSet(routeState, paramConfig.stateKey, paramConfig.initialState);
    });

    return objectMerge(acc, routeState);
}, {});
