import { useEffect, useState } from 'react';
import { Preview } from 'react-dnd-multi-backend';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

import { Checkbox, StyledTextField, SimpleModal, ButtonsBar, DropdownMenu } from '@labradorsports/components';
import { PlayOperations, PlaybookTabs, ShareLevels } from '@labradorsports/constants';
import {
    getCurrentFolderItems,
    useSite,
    getAllowedPlayOperations,
    getAllowedFolderOperations,
    countDisplay,
    getSelectedCount,
} from '@labradorsports/utils';

import { PlayType } from '../../shared/constants.js';
import { useDispatcher } from '../../shared/hooks/index.js';
import { DnDContext } from '../../shared/structural/index.js';
import {
    RootState,
    Selectors,
    playEditorActions,
    playbookActions,
    useCopyPlayMutation,
    useDeleteFolderMutation,
    useDeletePlayMutation,
    useHasPersonalQuery,
    useLoadViewedPlaysQuery,
    useShiftPlaybookItemsMutation,
    useMoveToFolderMutation,
    useMyPlaybookQuery,
    useTeamPlaybookQuery,
    useUnshareItemMutation,
    useDropPlaybookItemMutation,
    useShareItemsMutation,
} from '../../store/index.js';
import {
    PlayListItem,
    MoveToFolder,
    TeamItemsDropdown,
    ConfirmDeleteItemsModal,
    ConfirmUnshareItemsModal,
} from '../index.js';
import useStartSharing from '../sharing/useStartSharing.js';

interface Props {
    readOnly?: boolean;
    loadPlay?: (id?: string) => void;
    playbookType: string;
    onSelectionChange?: (selected: string[], selectedCount: number) => void;
    dropdowns?: boolean;
    selection?: boolean;
    dragAndDrop?: boolean;
}

const PlaylistItemDragPreview = ({ item, itemType, style }: any) => {
    // TODO: why is this preview getting items from other Dnd contexts?
    if (itemType !== PlayType) {
        return null;
    }

    return (
        <div
            style={{
                ...style,
                zIndex: 100,
                width: '350px',
                marginTop: '-60px',
            }}
        >
            {item.selectedItems ? (
                <div className="play-list-item list-item p-3">{item.selectedItems.length} items selected</div>
            ) : (
                <PlayListItem item={item} playlist={[]} hideDropdown readOnly />
            )}
        </div>
    );
};

const PlayList: FC<Props> = ({
    readOnly,
    loadPlay,
    playbookType,
    onSelectionChange,
    dropdowns,
    selection,
    dragAndDrop,
}) => {
    const [deletePlay] = useDeletePlayMutation();
    const openFolder = useDispatcher(playbookActions.OpenPlayFolder);
    const playModified = useDispatcher(playEditorActions.PlayModified);
    const [deleteFolder] = useDeleteFolderMutation();
    const [copyPlay] = useCopyPlayMutation();
    const [unshareItem] = useUnshareItemMutation();
    const [moveToFolder] = useMoveToFolderMutation();
    const [movePlaybookItem] = useShiftPlaybookItemsMutation();
    const [dropPlaybookItem] = useDropPlaybookItemMutation();
    const [shareItems] = useShareItemsMutation();
    const startShare = useStartSharing();

    const myPlaybookId = useSelector(Selectors.playbookId);
    const folderId = useSelector((state: RootState) => state.playbook.folderId);
    const openPlayId = useSelector((state: RootState) => state.playEditor.play?.id);
    const { data: hasPersonal } = useHasPersonalQuery();
    const activeTeam = useSelector(Selectors.activeTeam);
    const team = useSelector(Selectors.currentTeam);
    const teamRole = useSelector(Selectors.currentUserTeamRole);
    const { data: myPlaybook } = useMyPlaybookQuery();
    const { data: teamPlaybook } = useTeamPlaybookQuery();

    useLoadViewedPlaysQuery({ activeTeam, folderId }, { skip: playbookType !== PlaybookTabs.TEAM });

    const { Config, PlayConfig } = useSite();
    const [deleteItems, setDeleteItems] = useState([]);
    const [unshareItems, setUnshareItems] = useState([]);
    const [choosingFilter, setChoosingFilter] = useState(false);
    const [activeTags, setActiveTags] = useState([]);
    const [moveToFolderItems, setMoveToFolderItems] = useState([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const [copyingPlay, setCopyingPlay] = useState(null);
    const [copyPlayName, setCopyPlayName] = useState(null);
    const [flipCopyPlay, setFlipCopyPlay] = useState(false);
    const [selectedItemIds, _setSelectedItemIds] = useState([]);
    const isTeamPlaybook = playbookType === PlaybookTabs.TEAM;
    const playbookId = isTeamPlaybook ? teamPlaybook.id : myPlaybook.id;

    const [playlist = [], folders = []] = (() => {
        if (playbookType === PlaybookTabs.TEAM) {
            return teamPlaybook ? [teamPlaybook.shared, teamPlaybook.folders] : [];
        }

        return myPlaybook ? [myPlaybook.plays, myPlaybook.folders] : [];
    })();

    const hiddenPlays = playbookType === PlaybookTabs.MY && !hasPersonal ? playlist.slice(10) : [];
    const items = getCurrentFolderItems(playlist, folders, folderId);
    const findItem = (itemId) => items.find((i) => i.id === itemId);
    const selectedItems = selectedItemIds.map((itemId) => items.find((i) => i?.id === itemId)).filter(Boolean);

    const setSelectedItemIds = (items: string[]) => {
        _setSelectedItemIds(items);

        const selCount = getSelectedCount(Config, items, folders, playlist);
        setSelectedCount(selCount);

        if (onSelectionChange) {
            onSelectionChange(items, selCount);
        }
    };

    const deselectAll = () => {
        setSelectedItemIds([]);
    };

    useEffect(() => {
        deselectAll();
    }, [folderId, playbookType, activeTeam]);

    const cancelDelete = () => {
        setDeleteItems([]);
    };

    const confirmDelete = async () => {
        if (deleteItems.includes(openPlayId)) {
            playModified(false);
        }

        await Promise.all(
            deleteItems.map(async (itemId) => {
                const isFolder = folders.some((f) => f.id === itemId);

                if (isFolder) {
                    await deleteFolder({ playbookId, folderId: itemId });
                } else {
                    await deletePlay({ playbookId, playId: itemId });
                }
            })
        );

        deselectAll();
        cancelDelete();
    };

    const cancelUnshare = () => {
        setUnshareItems([]);
    };

    const confirmUnshare = async () => {
        if (unshareItems.includes(openPlayId)) {
            playModified(false);
        }

        await Promise.all(unshareItems.map(async (itemId) => unshareItem({ itemId })));

        deselectAll();
        cancelUnshare();
    };

    const changeFilter = (evt: any, checked: boolean) => {
        const { value } = evt.target;
        if (checked) {
            setActiveTags(activeTags.concat([value]));
        } else {
            setActiveTags(activeTags.filter((tag: string) => tag !== value));
        }
    };

    const toggleFilter = () => {
        setChoosingFilter(!choosingFilter);
    };

    const cancelMoveToFolder = () => {
        setMoveToFolderItems([]);
    };

    const confirmMoveToFolder = async (targetFolderId: string) => {
        await moveToFolder({
            itemIds: moveToFolderItems,
            targetFolderId,
        });
        deselectAll();
        cancelMoveToFolder();
    };

    const buttons = (action: AnyFunction) => [
        {
            text: 'Cancel',
        },
        {
            text: 'Continue',
            action,
            color: 'primary',
        },
    ];

    const included = [];

    const filtered = items
        .filter((play: any) => activeTags.length === 0 || activeTags.some((tag: string) => play.tags?.includes(tag)))
        // Only include a given item id once
        .filter((play: any) => {
            if (!included.includes(play.id)) {
                included.push(play.id);
                return true;
            }

            return false;
        })
        .map((item: any) => ({
            ...item,
            hidden: hiddenPlays.some((pl: any) => pl.id === item.id),
        }));

    const moveSelectedItems = (up: boolean) => {
        movePlaybookItem({
            itemIds: selectedItemIds,
            up,
        });
    };

    const copyPlayClick = (play: any) => {
        setCopyingPlay(play);
        setCopyPlayName(`Copy of ${play.name}`);
        setFlipCopyPlay(false);
    };

    const cancelCopyPlay = () => {
        setCopyingPlay(null);
    };

    const confirmCopyPlay = async () => {
        await copyPlay({
            playbookId: myPlaybookId,
            playId: copyingPlay.id,
            copyName: copyPlayName,
            flipHorizontal: flipCopyPlay,
            sourcePlaybookId: copyingPlay.playbookId,
        });

        cancelCopyPlay();
    };

    const copyPlayNameChange = (evt: any) => {
        setCopyPlayName(evt.target.value);
    };

    const flipCopyPlayChange = (evt: any, checked: boolean) => {
        setFlipCopyPlay(checked);
    };

    const selectItem = (itemId: any) => {
        const newSelected = selectedItemIds.includes(itemId)
            ? selectedItemIds.filter((id) => id !== itemId)
            : [...selectedItemIds, itemId];

        setSelectedItemIds(newSelected);
    };

    const allowedTo = (selectedItemIds ?? items).reduce((acc, itemId) => {
        const folder = folders.find((f) => f.id === itemId);
        const play = playlist.find((p) => p.id === itemId);

        const permissionsForItem = folder
            ? getAllowedFolderOperations(team, readOnly, playbookType, teamRole)
            : getAllowedPlayOperations(team, play, readOnly, playbookType, teamRole);

        // combine current permissions with permissions for this item using &&
        return Object.fromEntries(
            Object.entries(permissionsForItem).map(([k, v]) => {
                const accumulatedPerm = typeof acc[k] === 'boolean' ? acc[k] && v : v;

                return [k, accumulatedPerm];
            })
        );
    }, {});

    const moveItemsToFolderClick = () => {
        setMoveToFolderItems(selectedItemIds);
    };

    const sharePlayBulkClick = () => {
        startShare({
            itemIds: selectedItemIds,
        });

        deselectAll();
    };

    const deleteItemsClick = () => {
        setDeleteItems(selectedItemIds);
    };

    const unshareItemsClick = () => {
        setUnshareItems(selectedItemIds);
    };

    const setShareAudienceBulk = async (audience: string) => {
        await shareItems({
            srcPlaybookId: playbookId,
            modifications: {
                [activeTeam]: audience,
            },
            itemIds: selectedItemIds,
        });
    };

    const itemClick = (item) => {
        if (item.sport) {
            if (typeof loadPlay === 'function') {
                loadPlay(item.id);
            } else {
                selectItem(item.id);
            }
        } else {
            openFolder(item.id);
        }
    };

    const itemDrop = (target) => async (dropped, position) => {
        const itemIds = dropped.selectedItems ?? [dropped.id];
        if (position === 'middle') {
            await moveToFolder({
                itemIds,
                targetFolderId: target.id,
            });

            if (dropped.selectedItems) {
                deselectAll();
            }
        } else {
            const currentItemPosition = items.findIndex((item) => item.id === target.id);

            await dropPlaybookItem({
                itemIds,
                newPosition: position === 'top' ? currentItemPosition : currentItemPosition + 1,
            });
        }
    };

    const menuOptions: {
        text: string;
        onClick?: () => any;
        show: boolean;
    }[] = [
        {
            text: 'Move to Folder',
            onClick: moveItemsToFolderClick,
            show: allowedTo.MOVE,
        },
        {
            text: isTeamPlaybook ? 'Share to Another Playbook' : 'Share',
            onClick: sharePlayBulkClick,
            show: allowedTo.SHARE,
        },
        {
            text: 'Delete',
            onClick: deleteItemsClick,
            // TeamItemsDropdown handles remove button
            show: allowedTo.DELETE && !isTeamPlaybook,
        },
        {
            text: 'Move Up',
            onClick: () => moveSelectedItems(true),
            show: allowedTo.MOVE,
        },
        {
            text: 'Move Down',
            onClick: () => moveSelectedItems(false),
            show: allowedTo.MOVE,
        },
    ].filter(({ show }) => show);

    const selectedAudiences = selectedItemIds
        .map((id) => {
            const item = items.find((i) => i.id === id);

            if (!item) {
                return null;
            }

            const isPlay = Boolean(item.sport);

            if (isPlay) {
                return item.coachOnly ? ShareLevels.COACHES : ShareLevels.TEAM;
            }

            return 'folder';
        })
        .filter(Boolean);

    const uniqueAudiences = Array.from(new Set(selectedAudiences));
    const commonAudience = uniqueAudiences.length === 1 && uniqueAudiences[0] !== 'folder' ? uniqueAudiences[0] : null;

    return (
        <DnDContext>
            <Preview generator={PlaylistItemDragPreview} />
            <div className={`play-list ${selectedItemIds.length > 0 ? 'has-selected' : ''}`}>
                {moveToFolderItems.length > 0 ? (
                    <MoveToFolder
                        selectFolder={confirmMoveToFolder}
                        onCancel={cancelMoveToFolder}
                        itemIds={moveToFolderItems}
                    />
                ) : null}
                <ConfirmDeleteItemsModal
                    selectedItems={deleteItems.map(findItem).filter(Boolean)}
                    cancel={cancelDelete}
                    open={deleteItems.length > 0}
                    confirm={confirmDelete}
                />
                <ConfirmUnshareItemsModal
                    selectedItems={unshareItems.map(findItem).filter(Boolean)}
                    cancel={cancelUnshare}
                    open={unshareItems.length > 0}
                    confirm={confirmUnshare}
                />
                <SimpleModal buttons={buttons(confirmCopyPlay)} open={!!copyingPlay} onClose={cancelCopyPlay}>
                    <h3>
                        Copying <em>{copyingPlay?.name}</em>
                    </h3>
                    <div>
                        <StyledTextField
                            value={copyPlayName}
                            label="Name of Copy"
                            onChange={copyPlayNameChange}
                            fullWidth
                        />
                        <Checkbox
                            checked={flipCopyPlay}
                            onChange={flipCopyPlayChange}
                            label={`Flip ${PlayConfig.FieldDescriptor.toLowerCase()} horizontally`}
                        />
                    </div>
                </SimpleModal>
                <div className="tag-filter">
                    {choosingFilter ? (
                        <>
                            <a className="hide-filter" onClick={toggleFilter}>
                                Hide
                            </a>
                            {Config.PlayTags.map((tag) => (
                                <Checkbox
                                    className="tag-filter-item"
                                    key={tag}
                                    label={tag}
                                    value={tag}
                                    checked={activeTags.includes(tag)}
                                    onChange={changeFilter}
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            <a className="tags-filtered" onClick={toggleFilter}>
                                {activeTags.length > 0 ? `Filters: (${activeTags.length}) +` : 'Filter'}
                            </a>
                            {!readOnly && playbookType === PlaybookTabs.TEAM ? (
                                <span className="coach-only-note text-muted">
                                    <i>* Coaches only</i>
                                </span>
                            ) : null}
                        </>
                    )}
                </div>
                {filtered.map((item) => (
                    <PlayListItem
                        key={item.id}
                        item={item}
                        playlist={playlist}
                        activeTags={activeTags}
                        deleteClick={() => setDeleteItems([item.id])}
                        unshareClick={() => setUnshareItems([item.id])}
                        moveToFolder={() => setMoveToFolderItems([item.id])}
                        copyPlay={() => copyPlayClick(item)}
                        readOnly={readOnly}
                        playbookType={playbookType}
                        selectedItems={selectedItemIds}
                        onClick={() => itemClick(item)}
                        onSelect={selection ? () => selectItem(item.id) : null}
                        itemDrop={dragAndDrop ? itemDrop(item) : null}
                        hideDropdown={!dropdowns}
                    />
                ))}
                {filtered.length === 0 ? (
                    <p className="empty-filter">
                        {/* If there are 0 plays and we have no filter applied and not in a folder, the Playlist component will not be shown at all */}
                        {activeTags.length === 0
                            ? 'There are no plays in this folder yet. Use the ADD TO FOLDER menu option on a play to add one.'
                            : 'There are no plays matching your filter. Try updating your selection above.'}
                    </p>
                ) : null}
                {selectedItemIds.length > 0 ? (
                    <ButtonsBar>
                        <Button onClick={deselectAll}>Cancel</Button>
                        <div className="small flex-grow-1">
                            {countDisplay('item', selectedItemIds.length)} selected <br />(
                            {countDisplay('play', selectedCount)} total)
                        </div>
                        <DropdownMenu
                            options={menuOptions}
                            getMenuPosition={() => 'top'}
                            DropdownComponent={isTeamPlaybook && !readOnly ? TeamItemsDropdown : null}
                            dropdownProps={{
                                items: selectedItems,
                                allowedTo: {
                                    ...allowedTo,
                                    [PlayOperations.RENAME]: false,
                                },
                                shareAudience: commonAudience,
                                deleteClick: deleteItemsClick,
                                unshareClick: unshareItemsClick,
                                setShareAudience: setShareAudienceBulk,
                            }}
                        />
                    </ButtonsBar>
                ) : null}
            </div>
        </DnDContext>
    );
};

export default PlayList;
