import { createAssetGetter } from '@labradorsports/assets';
import { lacrosselab } from '@labradorsports/constants';

export const Logo = createAssetGetter('img/lacrosse/logo.svg');

export const SiteConfig = {
    ...lacrosselab.SiteConfig,
    AppStoreUrl: 'https://apps.apple.com/in/app/lacrosse-lab/id1545423864',
    GooglePlayUrl: 'https://play.google.com/store/apps/details?id=com.labradorsports.lacrosselab&hl=en_US&gl=US',
    Logo,
    PlaybookDescription:
        'Free play designer to create and share your lacrosse playbook. Create offense, defense and clearing plays. Add moves, passes, and comments to help guide your players.',
};

export const GoogleAnalyticsID = 'G-EJ0CGG2G1C';

export const Sports = lacrosselab.Sports;

export const SportsNames = {
    [Sports.MensLax]: "Men's Lacrosse",
    [Sports.WomensLax]: "Women's Lacrosse",
    [Sports.BoxLax]: 'Box Lacrosse',
};

export const PlayerPositions = {
    ATTACK: 'ATTACK',
    MIDFIELD: 'MIDFIELD',
    DEFENSE: 'DEFENSE',
    LSM: 'LSM',
    GOALKEEPER: 'GOALKEEPER',
    OFFMID: 'OFFMID',
    DEFMID: 'DEFMID',
    FORWARD: 'FORWARD',
    TRANSITION: 'TRANSITION',
    FACEOFF: 'FACEOFF',
};

export const PlayerPositionNames = {
    [PlayerPositions.ATTACK]: 'Attack',
    [PlayerPositions.MIDFIELD]: 'Midfield',
    [PlayerPositions.DEFENSE]: 'Defense',
    [PlayerPositions.LSM]: 'LSM',
    [PlayerPositions.GOALKEEPER]: 'Goalie',
    [PlayerPositions.OFFMID]: 'Offense Mid',
    [PlayerPositions.DEFMID]: 'Defense Mid',
    [PlayerPositions.FORWARD]: 'Forward',
    [PlayerPositions.TRANSITION]: 'Transition',
};

export const SportPositions = {
    [Sports.MensLax]: [
        PlayerPositions.ATTACK,
        PlayerPositions.MIDFIELD,
        PlayerPositions.DEFENSE,
        PlayerPositions.LSM,
        PlayerPositions.GOALKEEPER,
        PlayerPositions.OFFMID,
        PlayerPositions.DEFMID,
    ],
    [Sports.WomensLax]: [
        PlayerPositions.ATTACK,
        PlayerPositions.MIDFIELD,
        PlayerPositions.DEFENSE,
        PlayerPositions.GOALKEEPER,
    ],
    [Sports.BoxLax]: [
        PlayerPositions.FORWARD,
        PlayerPositions.TRANSITION,
        PlayerPositions.DEFENSE,
        PlayerPositions.GOALKEEPER,
    ],
};

export const PlayerRatingCategories = {
    SPEED: 'SPEED',
    AGILITY: 'AGILITY',
    STRENGTH: 'STRENGTH',
    STICK_STRONG: 'STICK_STRONG',
    STICK_WEAK: 'STICK_WEAK',
    SHOOT_WEAK: 'SHOOT_WEAK',
    SHOOT_STRONG: 'SHOOT_STRONG',
    GROUNDBALLS: 'GROUNDBALLS',
    OFFENSE_IQ: 'OFFENSE_IQ',
    DEFENSE_IQ: 'DEFENSE_IQ',
    OFFENSE_TRANSITION: 'OFFENSE_TRANSITION',
    DEFENSE_TRANSITION: 'DEFENSE_TRANSITION',
    SAVING: 'SAVING',
    COMMUNICATION: 'COMMUNICATION',
    GRIT: 'GRIT',
    CHECKING: 'CHECKING',
};

export const AttackRatingWeights = {
    [PlayerRatingCategories.SPEED]: 9,
    [PlayerRatingCategories.AGILITY]: 9,
    [PlayerRatingCategories.STRENGTH]: 6,
    [PlayerRatingCategories.STICK_STRONG]: 10,
    [PlayerRatingCategories.STICK_WEAK]: 8,
    [PlayerRatingCategories.SHOOT_WEAK]: 7,
    [PlayerRatingCategories.SHOOT_STRONG]: 9,
    [PlayerRatingCategories.GROUNDBALLS]: 5,
    [PlayerRatingCategories.OFFENSE_IQ]: 10,
    [PlayerRatingCategories.DEFENSE_IQ]: 1,
    [PlayerRatingCategories.OFFENSE_TRANSITION]: 8,
    [PlayerRatingCategories.DEFENSE_TRANSITION]: 3,
    [PlayerRatingCategories.SAVING]: 0,
    [PlayerRatingCategories.COMMUNICATION]: 5,
    [PlayerRatingCategories.GRIT]: 5,
    [PlayerRatingCategories.CHECKING]: 0,
};

export const DefenseRatingWeights = {
    [PlayerRatingCategories.SPEED]: 8,
    [PlayerRatingCategories.AGILITY]: 10,
    [PlayerRatingCategories.STRENGTH]: 9,
    [PlayerRatingCategories.STICK_STRONG]: 5,
    [PlayerRatingCategories.STICK_WEAK]: 1,
    [PlayerRatingCategories.SHOOT_WEAK]: 0,
    [PlayerRatingCategories.SHOOT_STRONG]: 1,
    [PlayerRatingCategories.GROUNDBALLS]: 9,
    [PlayerRatingCategories.OFFENSE_IQ]: 1,
    [PlayerRatingCategories.DEFENSE_IQ]: 10,
    [PlayerRatingCategories.OFFENSE_TRANSITION]: 5,
    [PlayerRatingCategories.DEFENSE_TRANSITION]: 5,
    [PlayerRatingCategories.SAVING]: 0.5,
    [PlayerRatingCategories.COMMUNICATION]: 9,
    [PlayerRatingCategories.GRIT]: 8,
    [PlayerRatingCategories.CHECKING]: 9,
};

export const LSMRatingWeights = {
    [PlayerRatingCategories.SPEED]: 9,
    [PlayerRatingCategories.AGILITY]: 9,
    [PlayerRatingCategories.STRENGTH]: 7,
    [PlayerRatingCategories.STICK_STRONG]: 7,
    [PlayerRatingCategories.STICK_WEAK]: 4,
    [PlayerRatingCategories.SHOOT_WEAK]: 0,
    [PlayerRatingCategories.SHOOT_STRONG]: 3,
    [PlayerRatingCategories.GROUNDBALLS]: 9,
    [PlayerRatingCategories.OFFENSE_IQ]: 2,
    [PlayerRatingCategories.DEFENSE_IQ]: 9,
    [PlayerRatingCategories.OFFENSE_TRANSITION]: 6,
    [PlayerRatingCategories.DEFENSE_TRANSITION]: 5,
    [PlayerRatingCategories.SAVING]: 0.5,
    [PlayerRatingCategories.COMMUNICATION]: 9,
    [PlayerRatingCategories.GRIT]: 8,
    [PlayerRatingCategories.CHECKING]: 9,
};

export const GoalkeeperRatingWeights = {
    [PlayerRatingCategories.SPEED]: 1,
    [PlayerRatingCategories.AGILITY]: 8,
    [PlayerRatingCategories.STRENGTH]: 1,
    [PlayerRatingCategories.STICK_STRONG]: 7,
    [PlayerRatingCategories.STICK_WEAK]: 0,
    [PlayerRatingCategories.SHOOT_WEAK]: 0,
    [PlayerRatingCategories.SHOOT_STRONG]: 0,
    [PlayerRatingCategories.GROUNDBALLS]: 7,
    [PlayerRatingCategories.OFFENSE_IQ]: 0,
    [PlayerRatingCategories.DEFENSE_IQ]: 10,
    [PlayerRatingCategories.OFFENSE_TRANSITION]: 6,
    [PlayerRatingCategories.DEFENSE_TRANSITION]: 3,
    [PlayerRatingCategories.SAVING]: 10,
    [PlayerRatingCategories.COMMUNICATION]: 10,
    [PlayerRatingCategories.GRIT]: 8,
    [PlayerRatingCategories.CHECKING]: 1,
};

export const MidfieldRatingWeights = {
    [PlayerRatingCategories.SPEED]: 9,
    [PlayerRatingCategories.AGILITY]: 9,
    [PlayerRatingCategories.STRENGTH]: 5,
    [PlayerRatingCategories.STICK_STRONG]: 8,
    [PlayerRatingCategories.STICK_WEAK]: 7,
    [PlayerRatingCategories.SHOOT_WEAK]: 5,
    [PlayerRatingCategories.SHOOT_STRONG]: 7,
    [PlayerRatingCategories.GROUNDBALLS]: 7,
    [PlayerRatingCategories.OFFENSE_IQ]: 7,
    [PlayerRatingCategories.DEFENSE_IQ]: 7,
    [PlayerRatingCategories.OFFENSE_TRANSITION]: 10,
    [PlayerRatingCategories.DEFENSE_TRANSITION]: 10,
    [PlayerRatingCategories.SAVING]: 0.5,
    [PlayerRatingCategories.COMMUNICATION]: 6,
    [PlayerRatingCategories.GRIT]: 9,
    [PlayerRatingCategories.CHECKING]: 0.5,
};

export const OffMidRatingWeights = {
    [PlayerRatingCategories.SPEED]: 9,
    [PlayerRatingCategories.AGILITY]: 8,
    [PlayerRatingCategories.STRENGTH]: 5,
    [PlayerRatingCategories.STICK_STRONG]: 10,
    [PlayerRatingCategories.STICK_WEAK]: 7,
    [PlayerRatingCategories.SHOOT_WEAK]: 6,
    [PlayerRatingCategories.SHOOT_STRONG]: 8,
    [PlayerRatingCategories.GROUNDBALLS]: 7,
    [PlayerRatingCategories.OFFENSE_IQ]: 8,
    [PlayerRatingCategories.DEFENSE_IQ]: 5,
    [PlayerRatingCategories.OFFENSE_TRANSITION]: 8,
    [PlayerRatingCategories.DEFENSE_TRANSITION]: 6,
    [PlayerRatingCategories.SAVING]: 0,
    [PlayerRatingCategories.COMMUNICATION]: 6,
    [PlayerRatingCategories.GRIT]: 7,
    [PlayerRatingCategories.CHECKING]: 1,
};

export const DefMidRatingWeights = {
    [PlayerRatingCategories.SPEED]: 9,
    [PlayerRatingCategories.AGILITY]: 9,
    [PlayerRatingCategories.STRENGTH]: 7,
    [PlayerRatingCategories.STICK_STRONG]: 7,
    [PlayerRatingCategories.STICK_WEAK]: 5,
    [PlayerRatingCategories.SHOOT_WEAK]: 3,
    [PlayerRatingCategories.SHOOT_STRONG]: 6,
    [PlayerRatingCategories.GROUNDBALLS]: 9,
    [PlayerRatingCategories.OFFENSE_IQ]: 3,
    [PlayerRatingCategories.DEFENSE_IQ]: 8,
    [PlayerRatingCategories.OFFENSE_TRANSITION]: 8,
    [PlayerRatingCategories.DEFENSE_TRANSITION]: 6,
    [PlayerRatingCategories.SAVING]: 0.5,
    [PlayerRatingCategories.COMMUNICATION]: 7,
    [PlayerRatingCategories.GRIT]: 8,
    [PlayerRatingCategories.CHECKING]: 2,
};

export const PlayerRatingWeights = {
    [PlayerPositions.ATTACK]: AttackRatingWeights,
    [PlayerPositions.MIDFIELD]: MidfieldRatingWeights,
    [PlayerPositions.DEFENSE]: DefenseRatingWeights,
    [PlayerPositions.LSM]: LSMRatingWeights,
    [PlayerPositions.GOALKEEPER]: GoalkeeperRatingWeights,
    [PlayerPositions.OFFMID]: OffMidRatingWeights,
    [PlayerPositions.DEFMID]: DefMidRatingWeights,
    [PlayerPositions.FORWARD]: AttackRatingWeights,
    [PlayerPositions.TRANSITION]: MidfieldRatingWeights,
};

export const PlayerRatingSections = [
    {
        title: 'Athleticism',
        ratings: [
            {
                key: PlayerRatingCategories.SPEED,
                title: 'Speed',
            },
            {
                key: PlayerRatingCategories.AGILITY,
                title: 'Agility',
            },
            {
                key: PlayerRatingCategories.STRENGTH,
                title: 'Strength',
            },
        ],
    },
    {
        title: 'Skills',
        ratings: [
            {
                key: PlayerRatingCategories.STICK_STRONG,
                title: 'Stick',
                subtitle: 'Strong',
            },
            {
                key: PlayerRatingCategories.STICK_WEAK,
                title: 'Stick',
                subtitle: 'Weak',
            },
            {
                key: PlayerRatingCategories.SHOOT_STRONG,
                title: 'Shoot',
                subtitle: 'Strong',
            },
            {
                key: PlayerRatingCategories.SHOOT_WEAK,
                title: 'Shoot',
                subtitle: 'Weak',
            },
            {
                key: PlayerRatingCategories.GROUNDBALLS,
                title: 'Groundballs',
            },
            {
                key: PlayerRatingCategories.CHECKING,
                title: 'Checking',
            },
            {
                key: PlayerRatingCategories.SAVING,
                title: 'Saving',
            },
        ],
    },
    {
        title: 'Awareness',
        ratings: [
            {
                key: PlayerRatingCategories.OFFENSE_IQ,
                title: 'Offense IQ',
            },
            {
                key: PlayerRatingCategories.DEFENSE_IQ,
                title: 'Defense IQ',
            },
            {
                key: PlayerRatingCategories.OFFENSE_TRANSITION,
                title: 'Offense Transition',
                subtitle: 'Clearing',
            },
            {
                key: PlayerRatingCategories.DEFENSE_TRANSITION,
                title: 'Defense Transition',
                subtitle: 'Riding',
            },
        ],
    },
    {
        title: 'Intangibles',
        ratings: [
            {
                key: PlayerRatingCategories.COMMUNICATION,
                title: 'Communication',
            },
            {
                key: PlayerRatingCategories.GRIT,
                title: 'Grit',
            },
        ],
    },
];

export const PlayTags = ['Offense', 'Extra-Man', 'Defense', 'Man-Down', 'Ride', 'Clear', 'Face-Off'];

export const DrillSkillTags = ['Stick-Work', 'Read-and-React', 'Transition'];

export const DepthSections = {
    attack: 'attack',
    midfield: 'midfield',
    faceoff: 'faceoff',
    ssdm: 'ssdm',
    lsm: 'lsm',
    defense: 'defense',
    goal: 'goal',
    manup: 'manup',
    mandown: 'mandown',
    forward: 'forward',
    transition: 'transition',
};

export const DepthConfig = {
    [Sports.MensLax]: {
        [DepthSections.attack]: {
            name: 'Attack',
            starters: 3,
        },
        [DepthSections.midfield]: {
            name: 'Midfield',
            starters: 3,
            maxLines: 3,
            linesKey: 'midLines',
        },
        [DepthSections.faceoff]: {
            name: 'Face-Off',
            starters: 1,
            optional: true,
        },
        [DepthSections.ssdm]: {
            name: 'Short Stick Defensive Midfield',
            shortName: 'SSDM',
            starters: 1,
            optional: true,
        },
        [DepthSections.lsm]: {
            name: 'Long Stick Midfield',
            starters: 1,
        },
        [DepthSections.defense]: {
            name: 'Defense',
            starters: 3,
        },
        [DepthSections.goal]: {
            name: 'Goalkeeper',
            starters: 1,
        },
        [DepthSections.manup]: {
            name: 'Man-Up',
            starters: 6,
        },
        [DepthSections.mandown]: {
            name: 'Man-Down',
            starters: 5,
        },
    },
    [Sports.WomensLax]: {
        [DepthSections.attack]: {
            name: 'Attack',
            starters: 4,
        },
        [DepthSections.midfield]: {
            name: 'Midfield',
            starters: 3,
            maxLines: 3,
            linesKey: 'midLines',
        },
        [DepthSections.defense]: {
            name: 'Defense',
            starters: 4,
        },
        [DepthSections.goal]: {
            name: 'Goalkeeper',
            starters: 1,
        },
    },
    [Sports.BoxLax]: {
        [DepthSections.forward]: {
            name: 'Forward',
            starters: 3,
        },
        [DepthSections.transition]: {
            name: 'Transition',
            starters: 3,
            maxLines: 3,
        },
        [DepthSections.defense]: {
            name: 'Defense',
            starters: 3,
        },
        [DepthSections.goal]: {
            name: 'Goalkeeper',
            starters: 1,
        },
    },
};

export const DepthChartLayout = {
    [Sports.MensLax]: [
        [DepthSections.attack, DepthSections.manup],
        [DepthSections.midfield],
        [DepthSections.faceoff, DepthSections.ssdm, DepthSections.lsm],
        [DepthSections.defense, DepthSections.mandown, DepthSections.goal],
    ],
    [Sports.WomensLax]: [
        [DepthSections.attack],
        [DepthSections.midfield],
        [DepthSections.defense],
        [DepthSections.goal],
    ],
    [Sports.BoxLax]: [
        [DepthSections.forward],
        [DepthSections.transition],
        [DepthSections.defense],
        [DepthSections.goal],
    ],
};

export const PlayerRoleNames = {
    [PlayerPositions.ATTACK]: 'Attack',
    [PlayerPositions.DEFENSE]: 'Defense',
    [PlayerPositions.GOALKEEPER]: 'Goalkeeper',
    [PlayerPositions.LSM]: 'Long-Stick Midfield',
    [PlayerPositions.MIDFIELD]: 'Midfield',
    [PlayerPositions.FACEOFF]: 'Face-Off',
};

export const TeamSizes = lacrosselab.TeamSizes;

export const AddonConfig = lacrosselab.AddonConfig;

export const AttendanceDisplayGroups = {
    [Sports.MensLax]: [
        [PlayerPositions.ATTACK],
        [PlayerPositions.DEFENSE],
        [PlayerPositions.MIDFIELD, PlayerPositions.OFFMID, PlayerPositions.DEFMID, PlayerPositions.LSM],
        [PlayerPositions.GOALKEEPER],
    ],
    [Sports.WomensLax]: [
        [PlayerPositions.ATTACK],
        [PlayerPositions.DEFENSE],
        [PlayerPositions.MIDFIELD],
        [PlayerPositions.GOALKEEPER],
    ],
    [Sports.BoxLax]: [
        [PlayerPositions.FORWARD],
        [PlayerPositions.DEFENSE],
        [PlayerPositions.TRANSITION],
        [PlayerPositions.GOALKEEPER],
    ],
};

export const ScoutingReportStats = {
    // Common
    GAMES: 'GAMES',
    GAMES_STARTED: 'GAMES_STARTED',
    GOALS: 'GOALS',
    GOALS_PER_GAME: 'GOALS_PER_GAME',
    ASSISTS: 'ASSISTS',
    SHOTS: 'SHOTS',
    SHOT_PERCENTAGE: 'SHOT_PERCENTAGE',
    SHOTS_ON_GOAL: 'SHOTS_ON_GOAL',
    SHOTS_ON_GOAL_PCT: 'SHOTS_ON_GOAL_PCT',
    SHOTS_PER_GAME: 'SHOTS_PER_GAME',
    OVERTIME: 'OVERTIME',
    GROUNDBALLS: 'GROUNDBALLS',
    TURNOVERS: 'TURNOVERS',
    CAUSED_TURNOVERS: 'CAUSED_TURNOVERS',
    CLEARS: 'CLEARS',
    CLEAR_PCT: 'CLEAR_PCT',

    // Men's Only
    MAN_UP: 'MAN_UP',
    MAN_DOWN: 'MAN_DOWN',
    FACEOFFS_W_L: 'FACEOFFS_W_L',
    FACEOFFS_PCT: 'FACEOFFS_PCT',
    PENALTIES: 'PENALTIES',
    PENALTY_MINUTES: 'PENALTY_MINUTES',

    // Women's Only
    FREE_POSITION: 'FREE_POSITION',
    WOMAN_UP: 'WOMAN_UP',
    WOMAN_DOWN: 'WOMAN_DOWN',
    FREE_POSITION_MADE_ATTEMPTS: 'FREE_POSITION_MADE_ATTEMPTS',
    FREE_POSITION_PCT: 'FREE_POSITION_PCT',
    DRAW_CONTROLS: 'DRAW_CONTROLS',
    FOULS: 'FOULS',
};

export const ScoutingReportStatDetails = {
    [ScoutingReportStats.GAMES]: {
        key: ScoutingReportStats.GAMES,
        name: 'Games',
        shortKey: 'GP',
    },
    [ScoutingReportStats.GAMES_STARTED]: {
        key: ScoutingReportStats.GAMES_STARTED,
        name: 'Games Started',
        shortKey: 'GS',
    },
    [ScoutingReportStats.GOALS]: {
        key: ScoutingReportStats.GOALS,
        shortKey: 'G',
        name: 'Goals',
    },
    [ScoutingReportStats.GOALS_PER_GAME]: {
        key: ScoutingReportStats.GOALS_PER_GAME,
        name: 'Goals per Game',
        formula: [ScoutingReportStats.GOALS, '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats.ASSISTS]: {
        key: ScoutingReportStats.ASSISTS,
        shortKey: 'AST',
        name: 'Assists',
    },
    [ScoutingReportStats.SHOTS]: { key: ScoutingReportStats.SHOTS, name: 'Shots' },
    [ScoutingReportStats.SHOT_PERCENTAGE]: {
        key: ScoutingReportStats.SHOT_PERCENTAGE,
        name: 'Shot Percentage',
        formula: [ScoutingReportStats.GOALS, '/', ScoutingReportStats.SHOTS],
        format: '%',
    },
    [ScoutingReportStats.SHOTS_ON_GOAL]: {
        key: ScoutingReportStats.SHOTS_ON_GOAL,
        name: 'Shots on Goal',
    },
    [ScoutingReportStats.SHOTS_ON_GOAL_PCT]: {
        key: ScoutingReportStats.SHOTS_ON_GOAL_PCT,
        name: 'Shots on Goal Percentage',
        formula: [ScoutingReportStats.SHOTS_ON_GOAL, '/', ScoutingReportStats.SHOTS],
        format: '%',
    },
    [ScoutingReportStats.SHOTS_PER_GAME]: {
        key: ScoutingReportStats.SHOTS_PER_GAME,
        name: 'Shots per Game',
        formula: [ScoutingReportStats.SHOTS, '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats.MAN_UP]: { key: ScoutingReportStats.MAN_UP, name: 'Man-up' },
    [ScoutingReportStats.MAN_DOWN]: { key: ScoutingReportStats.MAN_DOWN, name: 'Man-down' },
    [ScoutingReportStats.OVERTIME]: { key: ScoutingReportStats.OVERTIME, name: 'Overtime' },
    [ScoutingReportStats.GROUNDBALLS]: {
        key: ScoutingReportStats.GROUNDBALLS,
        shortKey: 'GB',
        name: 'Ground Balls',
    },
    [ScoutingReportStats.TURNOVERS]: {
        key: ScoutingReportStats.TURNOVERS,
        shortKey: 'TO',
        name: 'Turnovers',
        lowerIsBetter: true,
    },
    [ScoutingReportStats.CAUSED_TURNOVERS]: {
        key: ScoutingReportStats.CAUSED_TURNOVERS,
        name: 'Caused Turnovers',
    },
    [ScoutingReportStats.FACEOFFS_W_L]: {
        key: ScoutingReportStats.FACEOFFS_W_L,
        name: 'Faceoffs: W-L',
        format: '-',
    },
    [ScoutingReportStats.FACEOFFS_PCT]: {
        key: ScoutingReportStats.FACEOFFS_PCT,
        name: 'Faceoffs: Percentage',
        formula: ['a-b', ScoutingReportStats.FACEOFFS_W_L, 'a', '/', 'ab'],
        format: '%',
    },
    [ScoutingReportStats.CLEARS]: { key: ScoutingReportStats.CLEARS, name: 'Clears' },
    [ScoutingReportStats.CLEAR_PCT]: {
        key: ScoutingReportStats.CLEAR_PCT,
        name: 'Clear Percentage',
    },
    [ScoutingReportStats.PENALTIES]: {
        key: ScoutingReportStats.PENALTIES,
        name: 'Penalties',
        lowerIsBetter: true,
    },
    [ScoutingReportStats.PENALTY_MINUTES]: {
        key: ScoutingReportStats.PENALTY_MINUTES,
        name: 'Penalty Minutes',
        format: 'mm:ss',
        lowerIsBetter: true,
    },

    [ScoutingReportStats.WOMAN_UP]: { key: ScoutingReportStats.WOMAN_UP, name: 'Woman-up' },
    [ScoutingReportStats.WOMAN_DOWN]: {
        key: ScoutingReportStats.WOMAN_DOWN,
        name: 'Woman-down',
    },
    [ScoutingReportStats.FREE_POSITION_MADE_ATTEMPTS]: {
        key: ScoutingReportStats.FREE_POSITION_MADE_ATTEMPTS,
        name: 'Free Position: Made-Attempts',
        format: '-',
    },
    [ScoutingReportStats.FREE_POSITION]: {
        key: ScoutingReportStats.FREE_POSITION,
        name: 'Free Position',
    },
    [ScoutingReportStats.FREE_POSITION_PCT]: {
        key: ScoutingReportStats.FREE_POSITION_PCT,
        name: 'Free Position: Percentage',
        formula: ['a-b', ScoutingReportStats.FREE_POSITION_MADE_ATTEMPTS, 'a', '/', 'b'],
        format: '%',
    },
    [ScoutingReportStats.DRAW_CONTROLS]: {
        key: ScoutingReportStats.DRAW_CONTROLS,
        name: 'Draw Controls',
    },
    [ScoutingReportStats.FOULS]: {
        key: ScoutingReportStats.FOULS,
        name: 'Fouls',
    },
};

export const ScoutingReportStatDisplay = {
    [Sports.MensLax]: [
        {
            categoryName: 'PREVIEW',
            stats: [ScoutingReportStats.GAMES, ScoutingReportStats.GOALS, ScoutingReportStats.GOALS_PER_GAME],
        },
        {
            categoryName: 'Scoring',
            stats: [
                ScoutingReportStats.GAMES,
                ScoutingReportStats.GOALS,
                ScoutingReportStats.GOALS_PER_GAME,
                ScoutingReportStats.ASSISTS,
                ScoutingReportStats.SHOTS,
                ScoutingReportStats.SHOT_PERCENTAGE,
                ScoutingReportStats.SHOTS_ON_GOAL,
                ScoutingReportStats.SHOTS_ON_GOAL_PCT,
                ScoutingReportStats.SHOTS_PER_GAME,
            ],
        },
        {
            categoryName: 'Goal Breakdown',
            stats: [ScoutingReportStats.MAN_UP, ScoutingReportStats.MAN_DOWN, ScoutingReportStats.OVERTIME],
        },
        {
            categoryName: 'Miscellaneous',
            stats: [
                ScoutingReportStats.GROUNDBALLS,
                ScoutingReportStats.TURNOVERS,
                ScoutingReportStats.CAUSED_TURNOVERS,
                ScoutingReportStats.FACEOFFS_W_L,
                ScoutingReportStats.FACEOFFS_PCT,
                ScoutingReportStats.CLEARS,
                ScoutingReportStats.CLEAR_PCT,
                ScoutingReportStats.PENALTIES,
                ScoutingReportStats.PENALTY_MINUTES,
            ],
        },
    ],
    [Sports.WomensLax]: [
        {
            categoryName: 'PREVIEW',
            stats: [ScoutingReportStats.GAMES, ScoutingReportStats.GOALS, ScoutingReportStats.GOALS_PER_GAME],
        },
        {
            categoryName: 'Scoring',
            stats: [
                ScoutingReportStats.GAMES,
                ScoutingReportStats.GOALS,
                ScoutingReportStats.GOALS_PER_GAME,
                ScoutingReportStats.ASSISTS,
                ScoutingReportStats.SHOTS,
                ScoutingReportStats.SHOT_PERCENTAGE,
                ScoutingReportStats.SHOTS_ON_GOAL,
                ScoutingReportStats.SHOTS_ON_GOAL_PCT,
                ScoutingReportStats.SHOTS_PER_GAME,
            ],
        },
        {
            categoryName: 'Goal Breakdown',
            stats: [
                ScoutingReportStats.FREE_POSITION,
                ScoutingReportStats.WOMAN_UP,
                ScoutingReportStats.WOMAN_DOWN,
                ScoutingReportStats.OVERTIME,
            ],
        },
        {
            categoryName: 'Free Position Opportunites',
            stats: [ScoutingReportStats.FREE_POSITION_MADE_ATTEMPTS, ScoutingReportStats.FREE_POSITION_PCT],
        },
        {
            categoryName: 'Miscellaneous',
            stats: [
                ScoutingReportStats.GROUNDBALLS,
                ScoutingReportStats.TURNOVERS,
                ScoutingReportStats.CAUSED_TURNOVERS,
                ScoutingReportStats.DRAW_CONTROLS,
                ScoutingReportStats.CLEARS,
                ScoutingReportStats.CLEAR_PCT,
            ],
        },
    ],
    [Sports.BoxLax]: [
        {
            categoryName: 'PREVIEW',
            stats: [ScoutingReportStats.GAMES, ScoutingReportStats.GOALS, ScoutingReportStats.GOALS_PER_GAME],
        },
        {
            categoryName: 'Scoring',
            stats: [
                ScoutingReportStats.GAMES,
                ScoutingReportStats.GOALS,
                ScoutingReportStats.GOALS_PER_GAME,
                ScoutingReportStats.ASSISTS,
                ScoutingReportStats.SHOTS,
                ScoutingReportStats.SHOT_PERCENTAGE,
                ScoutingReportStats.SHOTS_ON_GOAL,
                ScoutingReportStats.SHOTS_ON_GOAL_PCT,
                ScoutingReportStats.SHOTS_PER_GAME,
            ],
        },
        {
            categoryName: 'Goal Breakdown',
            stats: [
                ScoutingReportStats.FREE_POSITION,
                ScoutingReportStats.WOMAN_UP,
                ScoutingReportStats.WOMAN_DOWN,
                ScoutingReportStats.OVERTIME,
            ],
        },
        {
            categoryName: 'Free Position Opportunites',
            stats: [ScoutingReportStats.FREE_POSITION_MADE_ATTEMPTS, ScoutingReportStats.FREE_POSITION_PCT],
        },
        {
            categoryName: 'Miscellaneous',
            stats: [
                ScoutingReportStats.GROUNDBALLS,
                ScoutingReportStats.TURNOVERS,
                ScoutingReportStats.CAUSED_TURNOVERS,
                ScoutingReportStats.DRAW_CONTROLS,
                ScoutingReportStats.CLEARS,
                ScoutingReportStats.CLEAR_PCT,
            ],
        },
    ],
};

export const PersonnelQuickStats = [
    ScoutingReportStats.GAMES,
    ScoutingReportStats.GOALS,
    ScoutingReportStats.ASSISTS,
    ScoutingReportStats.GROUNDBALLS,
    ScoutingReportStats.TURNOVERS,
];

export const Features = lacrosselab.Features;

const Config: ConfigSpec = {
    SiteConfig,
    GoogleAnalyticsID,
    Sports,
    PlayTags,
    DrillSkillTags,
    SportsNames,
    PlayerPositions,
    PlayerRatingWeights,
    DepthSections,
    DepthConfig,
    DepthChartLayout,
    PlayerPositionNames,
    PlayerRatingCategories,
    PlayerRatingSections,
    SportPositions,
    PlayerRoleNames,
    TeamSizes,
    AddonConfig,
    AttendanceDisplayGroups,
    ScoutingReportStats,
    ScoutingReportStatDetails,
    ScoutingReportStatDisplay,
    PersonnelQuickStats,
    Features,
};

export default Config;
